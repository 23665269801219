import { AttachmentFileTypes, AttachmentFileTypesDefault, AttachmentPolicy, AttachmentPolicyDefault, DownloadPolicy, DownloadPolicyDefault } from "@/suppport-attachment-types";
import { DomainSettings as DbDomain } from "@/API";
import { AuthorityGroupPolicy, CreateTopicAuthorityGroupPolicyDefault, UpdateTopicAuthorityGroupPolicyDefault } from "@/support-acl-types";

export class Domain {

    // 組織ID
    public readonly domainId: string;

    // 話題 > 作成権限 (デフォルト { admin: true, user: true })
    public readonly createTopicAuthorityGroupPolicy: AuthorityGroupPolicy;
    
    // 話題 > 更新権限 (デフォルト { admin: true })
    public readonly updateTopicAuthorityGroupPolicy: AuthorityGroupPolicy;

    // 添付設定
    public readonly attachmentPolicy: AttachmentPolicy;
    public readonly attachmentFileTypes: AttachmentFileTypes;

    // ダウンロード設定
    public readonly downloadPolicy: DownloadPolicy;

    public constructor( obj: { domainId: string } & Partial<Domain> ) {
        this.domainId = obj.domainId;  // 組織IDは確実に存在するようにする
        this.createTopicAuthorityGroupPolicy = 
            obj.createTopicAuthorityGroupPolicy ?
                { admin: obj.createTopicAuthorityGroupPolicy.admin, user: obj.createTopicAuthorityGroupPolicy.user } :
                    CreateTopicAuthorityGroupPolicyDefault;
        this.updateTopicAuthorityGroupPolicy =
            obj.updateTopicAuthorityGroupPolicy ?
                { admin: obj.updateTopicAuthorityGroupPolicy.admin } :
                    UpdateTopicAuthorityGroupPolicyDefault;
        this.attachmentPolicy = obj.attachmentPolicy || AttachmentPolicyDefault;
        if( obj.attachmentFileTypes ) {
            this.attachmentFileTypes = {
                image: obj.attachmentFileTypes.image,
                movie: obj.attachmentFileTypes.movie,
                pdf: obj.attachmentFileTypes.pdf,
                office: obj.attachmentFileTypes.office,
            }
        } else {
            this.attachmentFileTypes = AttachmentFileTypesDefault;
        }
        this.downloadPolicy = obj.downloadPolicy || DownloadPolicyDefault;
    }

    // 組織IDを指定して作成する
    public static create( obj: { domainId: string } & Partial<Domain> ) {
        return new Domain( obj );
    }

    public static createFrom( obj: { id: string } & Partial<DbDomain> ) {
        return new Domain( {
            domainId: obj.id,
            createTopicAuthorityGroupPolicy: obj.createTopicAuthorityGroupPolicy as AuthorityGroupPolicy,
            updateTopicAuthorityGroupPolicy: obj.updateTopicAuthorityGroupPolicy as AuthorityGroupPolicy,
            attachmentPolicy: obj.attachmentPolicy,
            attachmentFileTypes: obj.attachmentFileType as AttachmentFileTypes,
            downloadPolicy: obj.downloadPolicy,
        } );
    }
}
