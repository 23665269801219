











































































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import ProfileIcon from "../ProfileIcon.vue";
import NavBarSolutionButton from "@/components/solutions/NavBarSolutionButton.vue"
import type { IDirectUserInfo } from "../../direct-utility";
import { createDummyDirectUser } from '../../direct-utility';
import { allowFeature, FeatureName } from '../../direct-app-config';

export type NavigationItem = { to: string, label: string, click?: string };
import { User } from '@/model';
import { AppLinkItem } from '../solutions/types';


/**
 * ナビゲーションバー（右側）
 *
 * ok         OKボタン
 * complete   完了ボタン
 * items      指定メニュー
 * preset     プリセットメニュー（ユーザーアイコンから始まるメニュー）
 * close      ✕ボタン
 *
 */
@Component({
    components: { ProfileIcon, NavBarSolutionButton }
})
export default class NavBarRight extends Vue {
    name = "nav-bar-right";

    @Prop({ default: () => createDummyDirectUser() }) readonly user!: IDirectUserInfo;

    /** ナビゲーションの右側メニューアイテム */
    @Prop({ default: () => false }) readonly ok!: boolean;
    @Prop({ default: () => false }) readonly complete!: boolean;
    @Prop({ default: () => [] }) readonly items!: NavigationItem[];
    @Prop({ default: () => false }) readonly preset!: boolean;
    @Prop({ default: () => false }) readonly close!: boolean;
    @Prop({ default: () => false }) readonly appClose!: boolean;
    @Prop({ default: () => "" }) readonly domainId!: string;

    // ソリューションアイコン一覧
    @Prop({ default: () => [] }) readonly solutionLinks!: AppLinkItem[];

    get empty(): boolean    {
        return this.ok == false
                && this.complete == false
                && this.items.length == 0
                && this.preset == false
                && this.close == false
                && this.appClose == false
    }

    get userId(): string    { return this.user ? this.user.userId : "" }
    get userEmail(): string { return this.user ? this.user.email ?? "" : "" }
    get userName(): string  { return this.user ? this.user.name : "" }
    get userImage(): string { return this.user ? this.user.image : "" }

    get isEditDomainSetting(): boolean {
        const me: User =  this.$store.getters["users/me"]
        return me.isOwnerRole(this.domainId)
                || me.isAdminRole(this.domainId)
    }
    // デモ関連ハンドラ
    prepareDemoCategory()   { if( this.$root ) this.$root.$emit("prepare-demo-category"); }
    prepareDemoTopic()      { if( this.$root ) this.$root.$emit("prepare-demo-topic"); }
    prepareDemoMessage()    { if( this.$root ) this.$root.$emit("prepare-demo-message"); }
    prepareDemoComment()    { if( this.$root ) this.$root.$emit("prepare-demo-comment"); }

    prepareDemoImages() {
        if( this.$root ) this.$root.$emit("prepare-demo-images");
    }
    clearDemoImages() {
        if( this.$root ) this.$root.$emit("clear-demo-images");
    }

    // Feature
    hasFeature( keyword: FeatureName ): boolean { return allowFeature( keyword, this.$store ); }

    // 組織設定ダイアログ表示
    showDomainSetting() {
        this.$root.$emit("show-domain-setting")
    }

    // 同期する(PC)
    syncLoad() {
        this.$root.$emit('sync-data-alert');
    }

    logout() {
        this.$router.push("/logout");
    }

    // sentryテスト用のAPI呼び出し
    async onClickDebugSentry() {
        const { ServerApiAccess } = await import( '@/server-api-access' );
        ServerApiAccess.debugSentry();
    }
}
