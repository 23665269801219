import { Auth } from "aws-amplify";
import { CognitoUser, CognitoUserAttribute } from "amazon-cognito-identity-js";
import { DirectUsersMeType } from "./direct-restapi-types";

import { API, graphqlOperation } from "aws-amplify";
import { authenticatedImageUrl, listDomains } from "@/graphql/queries";
import { AuthenticatedImageUrlQuery, ListDomainsQuery, DirectDomainType } from "@/API";
import { DirectAccess, Domains, Provider } from "./direct-access";

import AppAuth from "./aws-config";
import { ServerApiAccess } from "./server-api-access";

// HaxeのInt64表現
declare class HaxeInt64 {
    high: number;
    low: number;
}

/** directのID表現のうち、_${high}_${low} になっているものを表す */
export type DirectHighLowIdStr = string;

/** directのID表現のうち、Int64をそのまま文字列化したもの */
export type DirectIdStr = string;

/** directのInt64形式 */
export type DirectInt64 = HaxeInt64;

export interface IDirectUserInfo {
    id: string;                     //!< UserPool の user id
    name: string;                   //!< direct の display name
    email: string;                  //!< direct の email(管理ユーザの場合は無い)
    userId: string;                 //!< direct の user id
    domains: Domains;               //!< direct の 所属組織ID一覧
    image: string;                  //!< ユーザープロフィール画像。未設定の場合は空文字
}
export const createDummyDirectUser = ():IDirectUserInfo => {
    return { id: "", name: "", email: "", userId: "", domains: [], image: "", }
}

export default class DirectUtility {

    private static accessToken: string = "";

    /** @deprecated */
    public static getAccessToken() { return DirectUtility.accessToken; }

    // CognitoUserAttribute のうち必要な属性値を取得する
    private static getUserValue( attrs: CognitoUserAttribute[], key: string ): string {
        const data = attrs.find( attr => attr.Name == key );
        if( data ) return data.Value;
        else return "";
    }

    private static getUserIdentities( attrs: CognitoUserAttribute[] ): Record<string, string> {
        const identities = this.getUserValue( attrs, "identities" );
        return JSON.parse(identities)[0];
    }

    /** directのログインユーザー情報を取得する */
    public static async getDirectUser(): Promise<IDirectUserInfo|number|undefined> {
        let user: CognitoUser;
        try {
            await Auth.currentSession();
            user = await Auth.currentAuthenticatedUser();
        } catch( err ) {
            return undefined;
        }

        const attrs = await Auth.userAttributes( user );

        // Cognito User Pool で取得可能な属性値（custom系はCognito側でカスタム属性値設定と、OIDC属性マッピングして下さい）
        const id = user.getUsername();
        const name = this.getUserValue( attrs, "name" );
        const email = this.getUserValue( attrs, "email" );
        const userId = this.getUserValue( attrs, "custom:direct_user_id" );
        const provider = this.getUserIdentities( attrs )["providerName"] as Provider;

        // 以下、direct から情報の取得
        const serverUserInfo = await AppAuth.getServerUserInfo();
        if( typeof serverUserInfo == "number" ) {
            switch( serverUserInfo ) {
                case 401: // 認証が無い
                    return 401;
                default:
                    return serverUserInfo;
            }
        } else if( serverUserInfo ) {
            this.accessToken = serverUserInfo.token || "";
            const domains = await this.getDirectDomainInfo();
            const profile = await this.getDirectProfileMe( provider );
            const profileImage = profile ? profile.profile_image_url : "";
            const info = {
                id, name, email, userId, domains, image: profileImage
            }
            return info;
        } else {
            return undefined;
        }
    }

    /** directの組織情報一覧を取得します（名前を順ソート済） */
    public static async getDirectDomainInfo(): Promise<Domains> {
        try {
            const api = new ServerApiAccess();
            const results = await api.listDomains();
            if( results && typeof results !== 'number' ) {
                return results;
            } else {
                return [];
            }
        } catch( err ) {
            console.error( err );
            return [];
        }
    }

    /** ログインユーザーのプロフィールを取得する */
    public static async getDirectProfileMe( provider: Provider ): Promise<DirectUsersMeType|undefined> {
        const direct = new DirectAccess( this.accessToken );
        const profile = await direct.getMe( provider );
        if( profile ) {
            const url = await this.getDirectAuthenticatedImageUrl( profile ? profile.profile_image_url : "" );
            if( url ) profile.profile_image_url = url;
        }
        return profile;
    }

    /** directの認証済み画像URLを取得する */
    public static async getDirectAuthenticatedImageUrl( url: string ): Promise<string|undefined> {
        if( !url ) return undefined;

        const op = graphqlOperation( authenticatedImageUrl, { url: url, token: this.accessToken } );
        const result = await( API.graphql( op ) as Promise<{data:AuthenticatedImageUrlQuery}> );

        if( !result.data.authenticatedImageUrl ) return undefined;
        if( result.data.authenticatedImageUrl.statusCode == 200 ) {
            return result.data.authenticatedImageUrl.body || undefined;
        } else {
            const error = result.data.authenticatedImageUrl;
            throw error;
        }
    }
}
