import { User, Topic, Message, Comment } from "@/model";
import UserDao from "./user-dao";
import type { UserReadResult } from "./user-dao";
import { ActionContext, Module } from "vuex";
import { State } from "vuex-class";
import { DirectDomainType, IconReactionType } from "@/API";
import { DirectDomainMembersType } from "@/direct-restapi-types";
import Vue from "vue";
import { IDirectUserInfo } from "@/direct-utility";
import { IndexdDB } from "@/indexddb";
import AclController from "@/components/acl/acl-controller";
import cloneDeep from "lodash/cloneDeep";

type LoadedFlag = { ddb: boolean, api: boolean };
type State = { users: { [domainId: string]: User[] }, me: User, domainMembers: { [domainId: string]: DirectDomainMembersType }, loginUser: number | IDirectUserInfo | undefined, loadedFlags: { [domainId: string]: LoadedFlag } }
type RootState = { domainId: string, topicId: string, messageId: string };
type UserActionContext = ActionContext< State, RootState >;

interface Payload<T> {
    data: T;            // カテゴリー
}
type PayloadAdd = {
    data: User,
    domainId: string,
};
type PayloadAddAll = {
    data: User[],
    domainId: string,
};
type PayloadReaction = {
    target: Topic | Message | Comment,
    type: IconReactionType,
    value: boolean,
};

async function setIndexedDB( state: State ): Promise<void> {
    await Promise.all( Object.keys(state.users).map( async( key ) => {
        await IndexdDB.set("USERS", key, state.users[key]);
    }))
}

/** 組織内のユーザー一覧を取得する */
const getDomainUsers = ( state: State, domainId: string ): User[] => {
    const result = (state.users[domainId] || [])
        .filter( u => {
            if( u.id == state.me.id ) return true;  // 自分は許可

            // 組織内かどうかチェック
            const index = u.domainIdList.findIndex( id => id == domainId );
            return 0 <= index;  // 組織外をフィルタリング
        })
        .map( u => {
            // 自分ならそのまま返す
            if( u.id == state.me.id ) return u;

            // 権限チェック（roleが取れ無い->members APIで取得できてない->権限外)
            const hasRole = u.getRoleId( domainId );
            if( hasRole ) return u;
            return User.createNotFoundUser(
                u.id,
                u.directId,
            )
        })
        || []
        ;
    return result;
}

/**
 * stateにuserを追加する
 * @param replace true: 上書きする
 */
function addUser( state: State, user: User, domainId: string, replace: boolean = false ) {
    if( !state.users[domainId] ) Vue.set( state.users, domainId, []);
    const users = state.users[domainId];
    const index = users.findIndex( u => u.id == user.id );
    if( index < 0 ) {               // 見つからず。追加
        users.push( user );
        return;
    } else if( replace == false ) { // 上書き無し
        return;
    } else {                        // 差し替え
        /**
         * 部署データとロールIDリストがキャッシュに存在する場合はそれをセットする
         */

        const domainRoles = users[index].domainRoles;
        Object.keys(domainRoles).map( domainId => {
            user.setDomainRole( domainId, domainRoles[domainId] );
        })
        const departments = users[index].departments;
        Object.keys(departments).map( domainId => {
            user.setDepartments( domainId, departments[domainId] );
        })
        users.splice( index, 1, user );
    }
}

const userModule: Module< State, RootState > = {
    namespaced: true,
    state: {
        users: {},
        me: User.createNotFoundUser("dummy"),
        domainMembers: {},
        loginUser: undefined,
        loadedFlags: {}, // DBとAPIのロードタイミングを同期させるためのフラグ
    } as State,
    getters: {
        /** ログインユーザー情報 */
        me: ( state: State ) => { return state.me },

        /** ユーザーIDを指定して取得する */
        get: ( state: State ) => ( cognitoUserId: string, domainId: string ): User|undefined => {
            return (state.users[domainId] || []).find( user => user.id == cognitoUserId );
        },

        /** 組織内のユーザー一覧を取得する */
        getByDomainId: ( state: State ) => ( domainId: string ): User[] => {
            return getDomainUsers( state, domainId );
        },

        /** domain members API の結果を取得する */
        getDomainMembers: ( state: State ) => ( domainId: string ): DirectDomainMembersType => {
            return state.domainMembers[ domainId ] || { contents: [] };
        },

        /** directログインユーザー情報(direct REST API)の結果を取得 
         * (me は cognitoのログインユーザー情報)
        */
        getLoginUser: ( state: State ) => { return state.loginUser },
    },
    mutations: {

        setMe( state: State, payload: PayloadAdd ): void {
            const me = Array.isArray( payload.data ) ? payload.data[0] : payload.data;
            state.me = me;
        },

        /**
         * Storeに追加する
         * @param state
         * @param payload
         * @returns
         */
        add( state: State, payload: PayloadAdd | PayloadAddAll ): void {
            if( Array.isArray( payload.data ) ) {
                ( payload.data || [] ).forEach( user => {
                    // DBから取得したデータは全て更新対象
                    addUser( state, user, payload.domainId, true );
                });
            } else {
                const user = payload.data;
                addUser( state, user, payload.domainId );
            }
        },

        /**
         * Reaction設定のON/OFFを切り替える。対象は自身のみ。(他人は設定不可)
         * @param state
         * @param payload 対象のトピックIDか、メッセージID
         */
        setMeReaction( state: State, payload: PayloadReaction ): void {
            const me = state.me;
            const target = payload.target.id;
            switch( payload.type ) {
                case IconReactionType.FAVORITE: me.setFavorite( target, payload.value ); break;
                case IconReactionType.LIKE:     me.setLike( target, payload.value ); break;
                default: { const unk: unknown = "error"; console.error( unk ); break; }
            }
        },

        /**
         * API結果とDBデータでstate.usersを更新する
         * @param state 
         * @param payload 組織ID
         */
        updateUsers( state: State, payload: { domainId: string } ): void {
            const domainId = payload.domainId;
            const domainUsers = state.domainMembers[ domainId ] || { contents: [] };
            const users = cloneDeep(state.users[domainId] || []);

            // usersに対して、role設定/所属部署情報を更新する
            const updated = domainUsers.contents.map( du => {
                const user = users.find( u => u.directId === du.user_id_str );
                // * domainMemebers + state.me と users を同期させる 
                // (ゲストの時に domainMemebers の結果がユーザのものしか返って来ないので domainMembersとusersに差異が出る)
                if( !user ) return;
                
                if( du.role_id ) {
                    user.setDomainRole(domainId, du.role_id);
                }
                if( du.departments ) {
                    user.setDepartments( domainId, du.departments );
                }
                return user;
            }).filter( u => u ) as User[];

            const me = updated.find( user => user.directId === state.me.directId );
            if( !me ) { updated.push( state.me ); }

            Vue.set(state.users, domainId, updated);
            // ロードフラグのリセット
            state.loadedFlags[domainId] = { ddb: false, api: false };
        },

        setDomainMembers( state: State, payload: { domainId: string, domainMembers: DirectDomainMembersType } ) {
            // domainMembers APIの結果をstate更新
            Vue.set( state.domainMembers, payload.domainId, payload.domainMembers );
        },

        setMeDomainSetting( state: State, payload: DirectDomainType[] ) {
            payload.forEach( domain => {
                if( domain.role ) {
                    Vue.set( state.me.domainRoles, domain.domain_id_str, domain.role.role_id, );
                }
            })
        },

        setLoginUser( state: State, payload: number | IDirectUserInfo | undefined ) {
            state.loginUser = payload;
        },

        /* dyanmoDBのロードフラグセット */
        setDDBLoadedFlag( state: State, param: { domainId: string, value: boolean } ) {
            if( !state.loadedFlags[param.domainId] ) {
                state.loadedFlags[param.domainId] = { ddb: false, api: false };
            }
            state.loadedFlags[param.domainId].ddb = param.value;
        },

        /** direct REST-APIのロードフラグセット */
        setAPILoadedFlag( state: State, param: { domainId: string, value: boolean } ) {
            if( !state.loadedFlags[param.domainId] ) {
                state.loadedFlags[param.domainId] = { ddb: false, api: false };
            }
            state.loadedFlags[param.domainId].api = param.value;
        },
    },

    actions: {

        /**
         * ユーザーを新規追加する
         * @param data 新規追加するユーザー
         * @return 追加されたユーザー
         */
        async create( { commit, rootState }: UserActionContext, payload: PayloadAdd ): Promise<User|undefined> {
            const user = payload.data;
            const register = await UserDao.create( user );
            if( register ) {
                commit( "add", { data: register, domainId: payload.domainId } );
                return register;
            } else {
                commit( "add", { data: user, domainId: payload.domainId } );
                return user;
            }
        },

        /**
         * Storeにユーザーを追加する.
         * data: 追加するユーザー
         */
        async add( { commit, state }: UserActionContext, payload: PayloadAdd ): Promise<void> {
            commit( "add", payload );
            await setIndexedDB( state );
        },

        /**
         * DBからユーザー一覧を取得してstoreに保存
         * @param data 取得するドメインID
         */
        async fetch( { dispatch, commit, state }: UserActionContext, payload: Payload<string|undefined> ): Promise<void> {
            const domainId = payload.data || "";
            if( !domainId ) {
                console.log("fetch user data must set domainid")
                return;
            }
            await dispatch( 'setDDBLoadedFlag', { domainId: domainId, value: false });
            let next = undefined;
            do {
                const readResult: UserReadResult = await UserDao.read( domainId, next );
                commit( "add", { data: readResult.users, domainId: domainId } );
                next = readResult.nextToken;
            } while( Boolean( next ) == true );
            await dispatch( 'setDDBLoadedFlag', { domainId: domainId, value: true });
            await setIndexedDB( state );
        },

        /**
         * ログイン情報を取得する
         * @param id: ログインユーザーのcognitoUserId
         */
        async fetchMe( { commit, getters }: UserActionContext, payload: { id: string } ): Promise<void> {
            try {
                const me = await UserDao.readByCognitoId( payload.id )
                if( me ) {
                    // storeに反映
                    const origin = getters.me as User;
                    origin.override( me );
                    commit( 'setMe', { data: origin } );
                }
            } catch( error ) {
                console.error( error );
            }
        },

        /** localStorageのusers/me情報をstoreへ反映させる用 */
        async setMe( { commit }, payload: User ): Promise<void> {
            commit( 'setMe', { data: payload } );
        },

        /** リアクション設定を変更する。設定対象は自分自身のみ。(他人の設定は不可) */
        async setMeReaction( { commit, getters }, payload: PayloadReaction ): Promise<void> {
            const me = getters.me as User;
            const target = payload.target.id;
            switch( payload.type ) {
                case IconReactionType.FAVORITE: {
                    const favorites = ([] as string[]).concat( me.favorites );  // コピー
                    User.setFavorite( target, payload.value, favorites );       // DB保存用favoritesの用意
                    await UserDao.update( me, { favorites } );  // DB更新
                    break;
                }
                case IconReactionType.LIKE: {
                    const likes = ([] as string[]).concat( me.likes );  // コピー
                    User.setLike( target, payload.value, likes );       // DB保存用likesの用意
                    await UserDao.update( me, { likes } );  // DB更新
                    break;
                }
                default: {
                    const error: unknown = "error";
                    console.error( error );
                    return;
                }
            }

            // storeを更新
            commit( 'setMeReaction', payload );
        },

        /** direct API の 組織メンバー情報を更新 */
        async resetUserDomainResults({ commit, state }, payload: { domainId: string, domainMembers: DirectDomainMembersType }): Promise<void> {
            commit( 'resetUserDomainResults', payload ); // 組織内Roleの調整
            await setIndexedDB( state );
        },

        /** REST-APIの結果 domainMemmbersをセット */
        async setDomainMembers( { commit }, payload: { domainId: string, domainMembers: DirectDomainMembersType }): Promise<void> {
            commit( "setDomainMembers", payload);
        },

        async setMeDomainSetting( { commit }, payload: DirectDomainType[] ): Promise<void> {
            commit( 'setMeDomainSetting', payload )
        },

        async setLoginUser( { commit }, payload: number | IDirectUserInfo | undefined ): Promise<void> {
            commit( 'setLoginUser', payload );
        },

        async restore({ state }): Promise<void> {
            const users = await IndexdDB.getAll("USERS");
            if( users ) {
                state.users = users;
            }
        },

        /* direct REST-API ロードタイミング管理 */
        async setAPILoadedFlag({ commit, state }, param: { domainId: string, value: boolean }): Promise<void> {
            commit( 'setAPILoadedFlag', param);
            // APIとDBの両ロード完了後にstate.usersを再度更新
            if( param.value ) {
                const loadedFlag = state.loadedFlags[param.domainId];
                if( loadedFlag.ddb && loadedFlag.api ) {
                    commit("updateUsers", { domainId: param.domainId })
                    await setIndexedDB( state )
                }
            }
        },

        /** dynamodDB ロードタイミング管理 */
        async setDDBLoadedFlag({ commit, state }, param: { domainId: string, value: boolean }): Promise<void> {
            commit( 'setDDBLoadedFlag', param);
            // APIとDBの両ロード完了後にstate.usersを再度更新
            if( param.value ) {
                const loadedFlag = state.loadedFlags[param.domainId];
                if( loadedFlag.ddb && loadedFlag.api ) {
                    commit("updateUsers", { domainId: param.domainId })
                    await setIndexedDB( state )
                }
            }
        },
    }
}

export default userModule;
