import { render, staticRenderFns } from "./DepartmentsSearchBox.vue?vue&type=template&id=af5f5874&scoped=true&"
import script from "./DepartmentsSearchBox.vue?vue&type=script&lang=ts&"
export * from "./DepartmentsSearchBox.vue?vue&type=script&lang=ts&"
import style0 from "./DepartmentsSearchBox.vue?vue&type=style&index=0&id=af5f5874&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "af5f5874",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src3578540509/src/dxflow-forum/packages/open-channel/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('af5f5874')) {
      api.createRecord('af5f5874', component.options)
    } else {
      api.reload('af5f5874', component.options)
    }
    module.hot.accept("./DepartmentsSearchBox.vue?vue&type=template&id=af5f5874&scoped=true&", function () {
      api.rerender('af5f5874', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/acl/group-uesr-list/Departments/DepartmentsSearchBox.vue"
export default component.exports