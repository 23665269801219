












































































































import { Component, Prop, Vue } from "vue-property-decorator";
import TopicListItem from "./TopicListItem.vue";
import IconChecker from "./icon/IconChecker.vue";
import CategoryModalDialog from "./CategoryModalDialog.vue";
import "vue-router"
import Fab from "./Fab.vue";
import "./topic-list.scss"
import store from "../store";
import { Category, Topic, User } from "../model";
import { IconReactionType } from "../API";
import { NavigationGuardNext, Route } from "vue-router";
import { ReactionController } from "@/model/reaction-controller";
import TopicEdit from "./TopicEdit.vue";
import { allowCreateTopic } from "../direct-app-config";
import { ActionPayload } from "vuex";
import { AttachmentFileTypesDefault, AttachmentFileTypes } from "@/suppport-attachment-types";

enum TypeFilter {
    None = "None",
    Star = "Star",
    Pin = "Pin",
    StarAndPin = "StarAndPin",
}

const topicFilterFunctor = ( filters: Category[], topic: Topic ) => {
    if( 0 === filters.length ) {
        return true;
    } else {
        return 0 <= filters.findIndex( filter => filter.id == topic.category.id );
    }
}

Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteUpdate',
])
@Component({
    components: {
        TopicListItem, IconChecker
        , CategoryModalDialog
        , Fab
        , TopicEdit
    }
})
export default class TopicList extends Vue {
    name: string = 'topic-list';
    isMobile: boolean = false;

    typeFilter: TypeFilter = TypeFilter.None;   // Watchでフィルタするかどうか
    allow_attachment_type: AttachmentFileTypes = AttachmentFileTypesDefault;

    unsubscribeAction?: () => void;             //!< VuexのsubscribeAction結果

    // 組織ID
    @Prop( { default: "" } ) readonly domainId!: string;

    // カテゴリー一覧
    @Prop({ default: () => [] }) readonly categories!: Category[];

    // 話題一覧
    @Prop({ default: () => [] }) readonly topicList!: Topic[];

    // 現在アクティブな話題ID
    @Prop({ default: "" }) readonly activeTopicId!: string;

    // ユーザー一覧
    @Prop({ default: () => [] }) readonly users!: User[];

    // ログインユーザー情報
    @Prop({ default: () => User.createNotFoundUser() }) readonly me!: User;

    // カテゴリーフィルタ
    @Prop({ default: () => [] }) readonly categoryFilter!: Category[];

    // Fabボタンを表示するか
    @Prop({ default: false }) readonly fab!: boolean;

    // true: サイドメニューの中
    @Prop({ default: false }) readonly inSidemenu!: boolean;

    get noItemsError(): string {
        return this.me.isGuestRole( this.domainId ) ? "ゲストの方はご覧いただけません" : "まだ話題はありません";
    }

    get newTopicUrl(): string {
        return `/${this.domainId}/new-topic`
    }

    /* リアクション情報をマージした配列を返す */
    get managedTopicList(): Topic[] {
        const ctl = new ReactionController( this.$store );
        if( this.$store ) {
            this.topicList.forEach( topic => {
                topic.star = ctl.getReaction( topic, IconReactionType.FAVORITE );
            })
        }
        return this.topicList;
    }

    // カテゴリーフィルタのボタンラベル
    get categoryFilterButtonLabel(): string {
        switch( this.categoryFilter.length ) {
            case 0: return "すべてのカテゴリー";
            default: return this.categoryFilter[0].title;
        }
    }
    get categoryFilterButtonSuffix(): string {
        return 1 < this.categoryFilter.length ? `他${this.categoryFilter.length -1}件` : "";
    }

    // 話題一覧（固定）
    get topicPinnedSource(): Topic[] {
        const result = this.managedTopicList.filter( ( topic ) => {
            if( !topic.pinned ) return false;
            return topicFilterFunctor( this.categoryFilter, topic );
        } );
        result.sort( (l, r) => {
            if( this.hasStar( l ) && this.hasStar( r) ) {   // 固定 & ☆同士
                return r.updatedAt.getTime() - l.updatedAt.getTime()
            } else if( this.hasStar( l ) ) {                // ☆の有る方が優先
                return -1;
            } else if( this.hasStar( r ) ) {                // ☆の有る方が優先
                return 1;
            } else {
                return r.updatedAt.getTime() - l.updatedAt.getTime()
            }
        } );
        return result;
    }

    // 話題一覧（★）
    get topicStarSource(): Topic[] {
        const result = this.managedTopicList.filter( (topic) => {
            if( topic.pinned ) return false;
            if( !this.hasStar( topic ) ) return false;
            return topicFilterFunctor( this.categoryFilter, topic );
        } );
        result.sort( (l, r) => r.updatedAt.getTime() - l.updatedAt.getTime() );
        return result;
    }

    // 話題一覧（固定無し／★無し）
    get topicListSource(): Topic[] {
        if( this.typeFilter == TypeFilter.Star ) return [];
        const result = this.managedTopicList.filter( topic => {
            if( this.hasStar( topic ) || topic.pinned ) return false;
            return topicFilterFunctor( this.categoryFilter, topic );
        });
        result.sort( (l, r) => r.updatedAt.getTime() - l.updatedAt.getTime() );
        return result;
    }

    private hasStar( topic: Topic ): boolean {
        if( topic.star ) {
            return topic.star.userIdList.includes( this.me.directId );
        } else {
            return false;
        }
    }

    // topic.categoryは更新がされないため、更新が入るcategoriesを利用する
    mergedCategory( topicCategory: Category ): Category {
        const category = this.categories.find( c => c.id == topicCategory.id );
        if( category ) { return category; }
        else { return topicCategory; }
    }

    // Fabボタンをクリックされたときの処理
    fabClicked(): void {
        if( allowCreateTopic( this.domainId, this.$store ) == false ) {
            this.$root.$emit('free-alert');
            return;
        }

        // モバイル版の場合は新規作成画面を開く
        // その他の場合はモーダル
        if( this.isMobile ) {
            this.$router.push( this.newTopicUrl );
        } else {
            this.$bvModal.show( "modal-topic" );
        }
    }

    /**
     * カテゴリーフィルタダイアログのクローズ時処理
     */
    onCloseCategoryFilterDialog( filter: Category[] ): void {
        if( this.$store ) {
            this.$store.dispatch("categories/setFilter", { domainId: this.domainId, filter } );
        } else {
            Vue.set( this, "categoryFilter", filter.slice() );
        }
    }

    created(): void {
        this.judgeMobile();
        window.addEventListener('resize', this.judgeMobile);
    }

    judgeMobile(): void {
        const mql = window.matchMedia('screen and (min-width: 768px)');
        this.isMobile = !mql.matches ? true : false;
    }

    // Lifecycle
    mounted(): void {
        if( this.$store && !this.unsubscribeAction ) {
            this.unsubscribeAction = this.$store.subscribeAction( {
                before: (action: ActionPayload) => {
                    if( action.type !== "topics/invalidate" ) return;       // invalidate以外無視
                    if( action.payload.domainId != this.domainId ) return;  // 同組織以外無視
                    // 指定話題の再ロードを行う
                    this.$store.dispatch( "topics/fetch", action.payload );
                }
            })
        }
    }
    updated(): void {
        if( !this.$store ) return;
        this.allow_attachment_type = this.$store.getters["domains/getAllowAttachmentType"] as AttachmentFileTypes;
    }
    beforeDestroy(): void {
        if( this.unsubscribeAction ) {
            this.unsubscribeAction();
            this.unsubscribeAction = undefined;
        }
    }

    // ナビゲーション前にデータをロードする
    beforeRouteEnter( to: Route, from: Route, next: NavigationGuardNext ): void {
        // directのトークから飛ぶことを想定
        const to_domainId = to.params.domainId || ( typeof to.query.domain_id == "string" ? to.query.domain_id : "" );
        if( !to.query.search ) {
            store.dispatch('fetchData', { domainId: to_domainId, prevDomainId: from.params.domainId  } )
        }

        next();
    }
    beforeRouteUpdate( to: Route, from: Route, next: NavigationGuardNext ): void {
        if( from.params.domainId != to.params.domainId ) {
            store.dispatch("fetchData", { domainId: to.params.domainId, prevDomainId: from.params.domainId } );
        }
        next();
    }

}
