








































































































































































































































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import DepartmentsTree from "./group-uesr-list/DepartmentsTree.vue";
import { Department } from "./group-uesr-list/Departments/values/Department";
import LowerList from "./LowerList.vue";
import { LowerListItem, IModeOption } from "./lower-list-type";
import { mixins } from "vue-class-component";
import EditDiscardMixin from "../mixin/EditDiscardMixin";
import Acl from "../../model/acl";
import AclController from "./acl-controller";
import Dropdown from "../common/CustomDropdown.vue";
import SearchWindow from "../common/SearchWindow.vue";
import CustomButton from "../button/CustomButon.vue";
import { User } from "@/model";
import { DirectDomainMembersType } from "@/direct-restapi-types";

export enum SearchMode {
    "ALL" = "すべて",
    "DEPARTMENT" = "部署",
    "USER" = "ユーザ",
}

@Component({
    mixins: [
        EditDiscardMixin
    ],
    components: {
        DepartmentsTree,
        LowerList,
        Dropdown,
        SearchWindow,
        CustomButton,
    }
})
export default class AclEditModal extends mixins( Vue, EditDiscardMixin ) {
    name: string = "acl-edit-modal";

    aclController: AclController = new AclController();
    departments: Department[] = [];

    // denyIdMap から 拒否したユーザ/部署ID の配列を生成
    denyIdList: string[] = [];

    // selectedIdMap から 選択したユーザ/部署ID の配列を生成
    selectedList: LowerListItem[] = [];

    lowerList: LowerListItem[] = [];
    routePath: Department[] = [];
    selectDepartmentIdStr: string = '';
    mode: SearchMode = SearchMode.ALL;
    text: string = '';
    
    searchOptions: SearchMode[] = [SearchMode.ALL, SearchMode.DEPARTMENT, SearchMode.USER]; 

    users: DirectDomainMembersType = { contents: [] };

    loading: boolean = false;
    searching: boolean = false;

    // リスト表示のモードのコントロール用
    activeIndex: number = 0;

    // 部署ツリーのローディング管理
    treeLoading: boolean = false;

    // データ構築(init)の動作制御 trueでinit実行
    startInit: boolean = true;

    @Prop({ default: '', required: true }) readonly id!: string;

    // storybook用
    @Prop({ default: () => [] }) readonly departmentsSource!: Department[];
    @Watch("departments", { immediate: true }) onDepartmentsChange() {
        if( !this.$store ) {
            this.departments = this.departmentsSource;
        }
    }

    // Storybook向け
    @Prop({ default: false }) readonly show!: boolean;

    // Storybook向け
    @Prop({ default: '' }) readonly setDomainId!: string;

    @Prop({ default: () => { return { contents: [] }; } }) readonly domainUsers!: DirectDomainMembersType;
    @Watch("domainUsers", { immediate: true }) async onDomainUsersChange( value: DirectDomainMembersType ) {
        if( value.contents.length > 0 ) {
            this.users = value;
            this.startInit = true;
        }
    }

    @Prop({ default: "" }) readonly targetId!: string;
    @Watch("targetId") async onTargetIdChanged() {
        if( this.$store ) {
            this.startInit = true;
            await this.init();
        }
    }

    @Prop({ default: "TOPIC" }) readonly targetType!: "TOPIC" | "MESSAGE" | "COMMENT";

    @Prop({ default: () => Acl.createDummy() }) readonly acl!: Acl;
    @Watch("acl") async onAclChanged() {
        if( this.$store ) {
            this.startInit = true;
            await this.init();
        }
    }

    // リストフィルターのモード一覧
    get modeOptions(): IModeOption[] {
        return this.departments.length ? [IModeOption.DEFAULT, IModeOption.USER, IModeOption.SET] : [IModeOption.USER, IModeOption.SET];
    }

    get modeIndex(): number {
        switch(this.mode) {
            case SearchMode.ALL: { return 0; }
            case SearchMode.DEPARTMENT: { return 1; }
            case SearchMode.USER: { return 2; }
            default: { return 0; }
        }
    }

    get searchMode(): SearchMode | undefined {
        if( !this.text ) { return undefined; }
        else { return this.mode; }
    }

    get titleLabel(): string {
        if( !this.$store ) return `閲覧権限設定`;
        switch( this.targetType ) {
            case "TOPIC": {
                const topic = this.$store.getters["topics/getOne"]( this.$store.getters["domainId"], this.targetId );
                if( topic ) {
                    return `話題: ${topic.title} の閲覧権限設定`
                } else {
                    return `新規話題の閲覧権限設定`;
                }
            }
            default: {
                return `閲覧権限設定`;
            }
        }
    }

    mounted(): void {
        if( this.show ) {
            this.$bvModal.show(this.id)
        }
    }

    async init(): Promise<void> {
        if( this.$store && !this.startInit ) return;
        if( this.$store && !this.$store.getters["domainId"] ) return;

        this.loading = true;

        this.aclController = new AclController();
        this.denyIdList = [];
        this.selectedList = [];
        this.lowerList = [];
        this.routePath = [];
        this.selectDepartmentIdStr = '';
        this.mode = SearchMode.ALL;
        this.text = '';
        this.activeIndex = 0;
        if( this.$store ) {
            const domainId = this.$store.getters["domainId"];
            const me = this.$store.getters["users/me"] as User;
            if( !me ) return;
            this.aclController.editExceptionUser(me.id, { owner: false, operator: true, admin: false });
            if( !this.$store.getters["users/getDomainMembers"](domainId) ) {
                await this.$store.dispatch('fetchDomainInfo', domainId);
            }
            this.users = this.$store.getters["users/getDomainMembers"](domainId);
            this.departments = this.$store.getters["domains/getDepartments"] || [];
            if( this.departments.length > 0 ) { this.selectDepartmentIdStr = this.departments[0].departmentIdStr; }
            this.aclController.init(domainId, this.users, this.departments, this.acl);
        } else {
            this.aclController.init(this.setDomainId, this.users, this.departments, this.acl);
        }
        this.updateList();
        this.startInit = false;
        this.loading = false;
    }
    
    // 許可[+]/拒否[-]ボタンを押した時の処理
    changeStatus( item: LowerListItem ): void {
        /**
         * action: 今回の操作 (true: 許可する false: 拒否する)
         * status: 操作前の状態 (true: 許可状態 false: 拒否状態)
         * isDeny: 拒否リスト(denyIdList)に含まれるか (= !status)
         */
        const status = !this.aclController.isDeny(item.id);
        const action = !status;

        this.aclController.syncLowerList(item, action, true);
        this.updateList();
    } 

    // 全てのチェック(チェックボックス)を押した時の処理
    selectedAll( checked: boolean ): void {
        const action = checked;
        // モードによって別処理
        switch(this.modeOptions[this.activeIndex]) {
            case IModeOption.DEFAULT: {
                // 親部署(現在洗濯中の部署)に対して 許可/拒否
                const item = this.aclController.convertLowerListItem(this.selectDepartmentIdStr);
                if( !item ) return;
                this.aclController.syncLowerList(item, action, true);
                this.updateList();
                break;
            }
            case IModeOption.USER: {
                // 下階層のユーザ全てに対して 許可/拒否
                this.lowerList.map( item => {
                    this.aclController.syncLowerList(item, action, true);
                })
                this.updateList();
                break;
            }
            default: {
                break;
            }
        }
    }

    // 全ての設定を初期状態に戻す
    resetAll(): void {
        this.aclController.getSetList().map( item => {
            // *初期設定が全て許可のため 一括true
            this.aclController.syncLowerList(item, true, true);  
        });
        this.updateList();
    }

    // ツリーから部署選択
    clickedDepartmentTree(department: Department): void {
        // 部署遷移がある時、SET のみモード変更
        if( this.modeOptions[this.activeIndex] === IModeOption.SET ) {
            this.activeIndex = 0;
        }

        const id = department.departmentIdStr;
        this.selectDepartment(id);
    }

    // 更新を表示へ反映させるための処理
    updateList(): void {
        // 拒否リスト更新
        this.denyIdList = this.aclController.getDenyIdList();
        // 選択リスト更新
        this.selectedList.splice(0, this.selectedList.length)
        this.selectedList.push(...this.aclController.getSelectedList());
        // LowerList(現在表示中の階層)更新
        this.selectDepartment( this.selectDepartmentIdStr );
    }
    
    // 部署を選択した時の処理
    selectDepartment( id: string ): void {
        const department = this.departments.find( dep => dep.departmentIdStr === id);

        this.selectDepartmentIdStr = id;

        this.lowerList.splice(0, this.lowerList.length);

        if( department ) { this.routePath = this.getRoutePath(department); }

        // モードによって別処理
        switch(this.modeOptions[this.activeIndex]) {
            case IModeOption.DEFAULT: {
                // 1つ下階層の 部署 + ユーザ
                if( !department ) return;
                this.lowerList.push(...this.aclController.getLowerDepartments(department));
                this.lowerList.push(...this.aclController.getLowerUsers(department));
                break;
            }
            case IModeOption.USER: {
                // 下階層のユーザ全て
                this.lowerList.push(...this.aclController.getLowerAllUsers(this.selectDepartmentIdStr));
                break;
            }
            case IModeOption.SET: {
                this.lowerList.push(...this.aclController.getSetList());
                break;
            }
            default: {
                break;
            }
        }
    }

    /**
     * 経路算出
     * (*速度重視のため、departmentsが正常な並びの時のみ正常に動作)
     */
    getRoutePath( department: Department ): Department[] {
        const index = this.departments.findIndex( dep => dep.departmentIdStr === department.departmentIdStr );
        const targets = this.departments.slice(0, index);
        const results: Department[] = [];
        let depth = department.depth;
        
        targets.reverse().forEach(target => {
            if( target.depth === depth - 1) {
                results.unshift( target );
                depth--;
            }
            if( depth === 0 ) return;
        })
        results.push(department);
        return results;
    }

    save(close: () => CloseEvent): void {
        const items = this.aclController.generateAcl();
        const acl = Acl.editAclItems(this.acl, items);
        this.$store.dispatch("setAclData", {id: this.targetId, acl: acl });
        close();
    }

    cancel(close: () => CloseEvent): void {
        const closeEvents = () => {
            if(this.selectedList.length > 0) {
                this.aclController.reset(this.acl);
            }
            this.startInit = true;
            close();
        }
        this.changeEditMode(this.selectedList.length > 0)
        this.showEditDiscardAlert(closeEvents);
    }

    search(text: string): void {
        if( !text ) return;
        this.text = text;

        this.loading = true;
        this.searching = true;
        this.routePath.splice(0, this.routePath.length);
        this.lowerList.splice(0, this.lowerList.length);
        const promises: ( () => LowerListItem[] )[] = [];

        // 部署検索
        if( this.mode === SearchMode.ALL ) {
            promises.push( () => this.aclController.search(this.text) );
        } else if( this.mode === SearchMode.DEPARTMENT ) {
            promises.push( () => this.aclController.search(this.text, "Department") );
        } else if( this.mode === SearchMode.USER ) {
            promises.push( () => this.aclController.search(this.text, "User") );
        }

        setTimeout(() => Promise.all(promises).then( results => {
            results.map( result => {
                this.lowerList.push(...result());
            })
            this.$nextTick(() => {
                this.loading = false;
                this.searching = false;
            });
        }), 100);
    }

    changeSearchMode(index: number) {
        const mode = this.searchOptions[index];
        if(!mode) return;
        this.mode = mode;
        this.search(this.text);
    }

    // 表示されるリストの内容を変更
    changeFilterMode(param: { index: number, select: string|undefined}): void {
        if( this.activeIndex === param.index ) return;
        this.activeIndex = param.index;
        const id = param.select ? param.select : this.selectDepartmentIdStr
        this.selectDepartment(id);
    }

    // 検索窓入力中の処理
    updateSearchWindowInput(updated: string): void {
        this.text = updated;
        if( !this.text ) {
            this.updateList();
        }
    }

    // ローディング用のイベント
    // setTimeoutでloadを挟むイベントと実行したい処理を並行で行う
    loadEvent(func: () => void): void {
        if( this.loading ) return;
        this.loading = true;
        setTimeout( () => {
            func();
            this.loading = false;
        }, 30);
    }
}
