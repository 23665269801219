var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("b-modal", {
    attrs: {
      id: _vm.id,
      "hide-header": "",
      "hide-footer": "",
      centered: _vm.centered,
      "no-close-on-backdrop": ""
    },
    on: { hide: _vm.hideEvent },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var ok = ref.ok
          var cancel = ref.cancel
          return [
            !_vm.help
              ? _c(
                  "b-row",
                  { staticClass: "alert-message", attrs: { "no-gutters": "" } },
                  [_vm._v(" " + _vm._s(_vm.msg) + " ")]
                )
              : _c(
                  "b-row",
                  { attrs: { "no-gutters": "" } },
                  [
                    _c("help-modal-content", {
                      attrs: {
                        "access-token": _vm.getAccessToken(),
                        "help-id": 20,
                        env: _vm.getStage()
                      }
                    })
                  ],
                  1
                ),
            _c(
              "b-row",
              { staticClass: "alert-footer", attrs: { "no-gutters": "" } },
              [
                !_vm.hideCancel
                  ? _c("cancel", {
                      staticClass: "col-5",
                      attrs: { label: _vm.cancelLabel },
                      on: {
                        cancel: function($event) {
                          return _vm.clickedCancel(cancel)
                        }
                      }
                    })
                  : _vm._e(),
                _vm.help
                  ? _c(
                      "b-button",
                      {
                        staticClass: "col-5",
                        attrs: {
                          variant: "info",
                          href: _vm.helpUrl,
                          target: "_blank",
                          rel: "noopener noreferrer"
                        }
                      },
                      [_vm._v(_vm._s(_vm.helpButtonText))]
                    )
                  : _vm._e(),
                _c("confirm", {
                  staticClass: "col-5",
                  attrs: { label: _vm.okLabel, variant: _vm.okVariant },
                  on: {
                    confirm: function($event) {
                      return _vm.clickedOK(ok)
                    }
                  }
                })
              ],
              1
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }