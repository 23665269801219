/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const createTopic = /* GraphQL */ `mutation CreateTopic(
  $input: CreateTopicInput!
  $condition: ModelTopicConditionInput
) {
  createTopic(input: $input, condition: $condition) {
    id
    domainId
    title
    desc
    icon {
      url
      mime
      __typename
    }
    pinned
    categoryId
    messages
    owner
    updatedAt
    createdAt
    deleted
    deletedUser
    acl {
      id
      refId
      refIdType
      base {
        create
        read
        update
        delete
        createTopic
        createMessage
        createComment
        createReactions
        __typename
      }
      guest {
        id
        refId
        refIdType
        create
        read
        update
        delete
        createTopic
        createMessage
        createComment
        createReactions
        __typename
      }
      items {
        id
        refId
        refIdType
        create
        read
        update
        delete
        createTopic
        createMessage
        createComment
        createReactions
        __typename
      }
      __typename
    }
    notification
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateTopicMutationVariables,
  APITypes.CreateTopicMutation
>;
export const updateTopic = /* GraphQL */ `mutation UpdateTopic(
  $input: UpdateTopicInput!
  $condition: ModelTopicConditionInput
) {
  updateTopic(input: $input, condition: $condition) {
    id
    domainId
    title
    desc
    icon {
      url
      mime
      __typename
    }
    pinned
    categoryId
    messages
    owner
    updatedAt
    createdAt
    deleted
    deletedUser
    acl {
      id
      refId
      refIdType
      base {
        create
        read
        update
        delete
        createTopic
        createMessage
        createComment
        createReactions
        __typename
      }
      guest {
        id
        refId
        refIdType
        create
        read
        update
        delete
        createTopic
        createMessage
        createComment
        createReactions
        __typename
      }
      items {
        id
        refId
        refIdType
        create
        read
        update
        delete
        createTopic
        createMessage
        createComment
        createReactions
        __typename
      }
      __typename
    }
    notification
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTopicMutationVariables,
  APITypes.UpdateTopicMutation
>;
export const deleteTopic = /* GraphQL */ `mutation DeleteTopic(
  $input: DeleteTopicInput!
  $condition: ModelTopicConditionInput
) {
  deleteTopic(input: $input, condition: $condition) {
    id
    domainId
    title
    desc
    icon {
      url
      mime
      __typename
    }
    pinned
    categoryId
    messages
    owner
    updatedAt
    createdAt
    deleted
    deletedUser
    acl {
      id
      refId
      refIdType
      base {
        create
        read
        update
        delete
        createTopic
        createMessage
        createComment
        createReactions
        __typename
      }
      guest {
        id
        refId
        refIdType
        create
        read
        update
        delete
        createTopic
        createMessage
        createComment
        createReactions
        __typename
      }
      items {
        id
        refId
        refIdType
        create
        read
        update
        delete
        createTopic
        createMessage
        createComment
        createReactions
        __typename
      }
      __typename
    }
    notification
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteTopicMutationVariables,
  APITypes.DeleteTopicMutation
>;
export const createMessage = /* GraphQL */ `mutation CreateMessage(
  $input: CreateMessageInput!
  $condition: ModelMessageConditionInput
) {
  createMessage(input: $input, condition: $condition) {
    id
    domainId
    topicId
    title
    message
    pinned
    owner
    commentId
    photos {
      url
      mime
      __typename
    }
    updatedAt
    createdAt
    deleted
    deletedUser
    acl {
      id
      refId
      refIdType
      base {
        create
        read
        update
        delete
        createTopic
        createMessage
        createComment
        createReactions
        __typename
      }
      guest {
        id
        refId
        refIdType
        create
        read
        update
        delete
        createTopic
        createMessage
        createComment
        createReactions
        __typename
      }
      items {
        id
        refId
        refIdType
        create
        read
        update
        delete
        createTopic
        createMessage
        createComment
        createReactions
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateMessageMutationVariables,
  APITypes.CreateMessageMutation
>;
export const updateMessage = /* GraphQL */ `mutation UpdateMessage(
  $input: UpdateMessageInput!
  $condition: ModelMessageConditionInput
) {
  updateMessage(input: $input, condition: $condition) {
    id
    domainId
    topicId
    title
    message
    pinned
    owner
    commentId
    photos {
      url
      mime
      __typename
    }
    updatedAt
    createdAt
    deleted
    deletedUser
    acl {
      id
      refId
      refIdType
      base {
        create
        read
        update
        delete
        createTopic
        createMessage
        createComment
        createReactions
        __typename
      }
      guest {
        id
        refId
        refIdType
        create
        read
        update
        delete
        createTopic
        createMessage
        createComment
        createReactions
        __typename
      }
      items {
        id
        refId
        refIdType
        create
        read
        update
        delete
        createTopic
        createMessage
        createComment
        createReactions
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateMessageMutationVariables,
  APITypes.UpdateMessageMutation
>;
export const deleteMessage = /* GraphQL */ `mutation DeleteMessage(
  $input: DeleteMessageInput!
  $condition: ModelMessageConditionInput
) {
  deleteMessage(input: $input, condition: $condition) {
    id
    domainId
    topicId
    title
    message
    pinned
    owner
    commentId
    photos {
      url
      mime
      __typename
    }
    updatedAt
    createdAt
    deleted
    deletedUser
    acl {
      id
      refId
      refIdType
      base {
        create
        read
        update
        delete
        createTopic
        createMessage
        createComment
        createReactions
        __typename
      }
      guest {
        id
        refId
        refIdType
        create
        read
        update
        delete
        createTopic
        createMessage
        createComment
        createReactions
        __typename
      }
      items {
        id
        refId
        refIdType
        create
        read
        update
        delete
        createTopic
        createMessage
        createComment
        createReactions
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteMessageMutationVariables,
  APITypes.DeleteMessageMutation
>;
export const createComment = /* GraphQL */ `mutation CreateComment(
  $input: CreateCommentInput!
  $condition: ModelCommentConditionInput
) {
  createComment(input: $input, condition: $condition) {
    id
    domainId
    topicId
    messageId
    message
    photos {
      url
      mime
      __typename
    }
    owner
    updatedAt
    createdAt
    deleted
    deletedUser
    acl {
      id
      refId
      refIdType
      base {
        create
        read
        update
        delete
        createTopic
        createMessage
        createComment
        createReactions
        __typename
      }
      guest {
        id
        refId
        refIdType
        create
        read
        update
        delete
        createTopic
        createMessage
        createComment
        createReactions
        __typename
      }
      items {
        id
        refId
        refIdType
        create
        read
        update
        delete
        createTopic
        createMessage
        createComment
        createReactions
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCommentMutationVariables,
  APITypes.CreateCommentMutation
>;
export const updateComment = /* GraphQL */ `mutation UpdateComment(
  $input: UpdateCommentInput!
  $condition: ModelCommentConditionInput
) {
  updateComment(input: $input, condition: $condition) {
    id
    domainId
    topicId
    messageId
    message
    photos {
      url
      mime
      __typename
    }
    owner
    updatedAt
    createdAt
    deleted
    deletedUser
    acl {
      id
      refId
      refIdType
      base {
        create
        read
        update
        delete
        createTopic
        createMessage
        createComment
        createReactions
        __typename
      }
      guest {
        id
        refId
        refIdType
        create
        read
        update
        delete
        createTopic
        createMessage
        createComment
        createReactions
        __typename
      }
      items {
        id
        refId
        refIdType
        create
        read
        update
        delete
        createTopic
        createMessage
        createComment
        createReactions
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCommentMutationVariables,
  APITypes.UpdateCommentMutation
>;
export const deleteComment = /* GraphQL */ `mutation DeleteComment(
  $input: DeleteCommentInput!
  $condition: ModelCommentConditionInput
) {
  deleteComment(input: $input, condition: $condition) {
    id
    domainId
    topicId
    messageId
    message
    photos {
      url
      mime
      __typename
    }
    owner
    updatedAt
    createdAt
    deleted
    deletedUser
    acl {
      id
      refId
      refIdType
      base {
        create
        read
        update
        delete
        createTopic
        createMessage
        createComment
        createReactions
        __typename
      }
      guest {
        id
        refId
        refIdType
        create
        read
        update
        delete
        createTopic
        createMessage
        createComment
        createReactions
        __typename
      }
      items {
        id
        refId
        refIdType
        create
        read
        update
        delete
        createTopic
        createMessage
        createComment
        createReactions
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCommentMutationVariables,
  APITypes.DeleteCommentMutation
>;
export const createCategory = /* GraphQL */ `mutation CreateCategory(
  $input: CreateCategoryInput!
  $condition: ModelCategoryConditionInput
) {
  createCategory(input: $input, condition: $condition) {
    id
    title
    domainId
    color
    owner
    updatedAt
    createdAt
    deleted
    deletedUser
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCategoryMutationVariables,
  APITypes.CreateCategoryMutation
>;
export const updateCategory = /* GraphQL */ `mutation UpdateCategory(
  $input: UpdateCategoryInput!
  $condition: ModelCategoryConditionInput
) {
  updateCategory(input: $input, condition: $condition) {
    id
    title
    domainId
    color
    owner
    updatedAt
    createdAt
    deleted
    deletedUser
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCategoryMutationVariables,
  APITypes.UpdateCategoryMutation
>;
export const deleteCategory = /* GraphQL */ `mutation DeleteCategory(
  $input: DeleteCategoryInput!
  $condition: ModelCategoryConditionInput
) {
  deleteCategory(input: $input, condition: $condition) {
    id
    title
    domainId
    color
    owner
    updatedAt
    createdAt
    deleted
    deletedUser
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCategoryMutationVariables,
  APITypes.DeleteCategoryMutation
>;
export const createUser = /* GraphQL */ `mutation CreateUser(
  $input: CreateUserInput!
  $condition: ModelUserConditionInput
) {
  createUser(input: $input, condition: $condition) {
    id
    directId
    name
    profileIcon
    domainIdList
    owner
    favorites
    likes
    updatedAt
    createdAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserMutationVariables,
  APITypes.CreateUserMutation
>;
export const updateUser = /* GraphQL */ `mutation UpdateUser(
  $input: UpdateUserInput!
  $condition: ModelUserConditionInput
) {
  updateUser(input: $input, condition: $condition) {
    id
    directId
    name
    profileIcon
    domainIdList
    owner
    favorites
    likes
    updatedAt
    createdAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserMutationVariables,
  APITypes.UpdateUserMutation
>;
export const deleteUser = /* GraphQL */ `mutation DeleteUser(
  $input: DeleteUserInput!
  $condition: ModelUserConditionInput
) {
  deleteUser(input: $input, condition: $condition) {
    id
    directId
    name
    profileIcon
    domainIdList
    owner
    favorites
    likes
    updatedAt
    createdAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserMutationVariables,
  APITypes.DeleteUserMutation
>;
export const createDomainUser = /* GraphQL */ `mutation CreateDomainUser(
  $input: CreateDomainUserInput!
  $condition: ModelDomainUserConditionInput
) {
  createDomainUser(input: $input, condition: $condition) {
    domainId
    userDirectId
    user {
      id
      directId
      name
      profileIcon
      domainIdList
      owner
      favorites
      likes
      updatedAt
      createdAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateDomainUserMutationVariables,
  APITypes.CreateDomainUserMutation
>;
export const updateDomainUser = /* GraphQL */ `mutation UpdateDomainUser(
  $input: UpdateDomainUserInput!
  $condition: ModelDomainUserConditionInput
) {
  updateDomainUser(input: $input, condition: $condition) {
    domainId
    userDirectId
    user {
      id
      directId
      name
      profileIcon
      domainIdList
      owner
      favorites
      likes
      updatedAt
      createdAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateDomainUserMutationVariables,
  APITypes.UpdateDomainUserMutation
>;
export const deleteDomainUser = /* GraphQL */ `mutation DeleteDomainUser(
  $input: DeleteDomainUserInput!
  $condition: ModelDomainUserConditionInput
) {
  deleteDomainUser(input: $input, condition: $condition) {
    domainId
    userDirectId
    user {
      id
      directId
      name
      profileIcon
      domainIdList
      owner
      favorites
      likes
      updatedAt
      createdAt
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteDomainUserMutationVariables,
  APITypes.DeleteDomainUserMutation
>;
export const createDepartment = /* GraphQL */ `mutation CreateDepartment(
  $input: CreateDepartmentInput!
  $condition: ModelDepartmentConditionInput
) {
  createDepartment(input: $input, condition: $condition) {
    domainId
    department_id
    name
    user_defined_key
    order
    parent
    node
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateDepartmentMutationVariables,
  APITypes.CreateDepartmentMutation
>;
export const updateDepartment = /* GraphQL */ `mutation UpdateDepartment(
  $input: UpdateDepartmentInput!
  $condition: ModelDepartmentConditionInput
) {
  updateDepartment(input: $input, condition: $condition) {
    domainId
    department_id
    name
    user_defined_key
    order
    parent
    node
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateDepartmentMutationVariables,
  APITypes.UpdateDepartmentMutation
>;
export const deleteDepartment = /* GraphQL */ `mutation DeleteDepartment(
  $input: DeleteDepartmentInput!
  $condition: ModelDepartmentConditionInput
) {
  deleteDepartment(input: $input, condition: $condition) {
    domainId
    department_id
    name
    user_defined_key
    order
    parent
    node
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteDepartmentMutationVariables,
  APITypes.DeleteDepartmentMutation
>;
export const createNotifications = /* GraphQL */ `mutation CreateNotifications(
  $input: CreateNotificationsInput!
  $condition: ModelNotificationsConditionInput
) {
  createNotifications(input: $input, condition: $condition) {
    id
    domainId
    title
    desc
    users
    departments
    exclude_users
    exclude_departments
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateNotificationsMutationVariables,
  APITypes.CreateNotificationsMutation
>;
export const updateNotifications = /* GraphQL */ `mutation UpdateNotifications(
  $input: UpdateNotificationsInput!
  $condition: ModelNotificationsConditionInput
) {
  updateNotifications(input: $input, condition: $condition) {
    id
    domainId
    title
    desc
    users
    departments
    exclude_users
    exclude_departments
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateNotificationsMutationVariables,
  APITypes.UpdateNotificationsMutation
>;
export const deleteNotifications = /* GraphQL */ `mutation DeleteNotifications(
  $input: DeleteNotificationsInput!
  $condition: ModelNotificationsConditionInput
) {
  deleteNotifications(input: $input, condition: $condition) {
    id
    domainId
    title
    desc
    users
    departments
    exclude_users
    exclude_departments
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteNotificationsMutationVariables,
  APITypes.DeleteNotificationsMutation
>;
export const createIconReaction = /* GraphQL */ `mutation CreateIconReaction(
  $input: CreateIconReactionInput!
  $condition: ModelIconReactionConditionInput
) {
  createIconReaction(input: $input, condition: $condition) {
    type
    targetType
    domainId
    topicId
    messageId
    commentId
    userIdList
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateIconReactionMutationVariables,
  APITypes.CreateIconReactionMutation
>;
export const updateIconReaction = /* GraphQL */ `mutation UpdateIconReaction(
  $input: UpdateIconReactionInput!
  $condition: ModelIconReactionConditionInput
) {
  updateIconReaction(input: $input, condition: $condition) {
    type
    targetType
    domainId
    topicId
    messageId
    commentId
    userIdList
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateIconReactionMutationVariables,
  APITypes.UpdateIconReactionMutation
>;
export const deleteIconReaction = /* GraphQL */ `mutation DeleteIconReaction(
  $input: DeleteIconReactionInput!
  $condition: ModelIconReactionConditionInput
) {
  deleteIconReaction(input: $input, condition: $condition) {
    type
    targetType
    domainId
    topicId
    messageId
    commentId
    userIdList
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteIconReactionMutationVariables,
  APITypes.DeleteIconReactionMutation
>;
export const createDomainSettings = /* GraphQL */ `mutation CreateDomainSettings(
  $input: CreateDomainSettingsInput!
  $condition: ModelDomainSettingsConditionInput
) {
  createDomainSettings(input: $input, condition: $condition) {
    id
    createTopicAuthorityGroupPolicy {
      admin
      user
      __typename
    }
    updateTopicAuthorityGroupPolicy {
      admin
      user
      __typename
    }
    attachmentPolicy
    attachmentFileType {
      image
      movie
      pdf
      office
      __typename
    }
    downloadPolicy
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateDomainSettingsMutationVariables,
  APITypes.CreateDomainSettingsMutation
>;
export const updateDomainSettings = /* GraphQL */ `mutation UpdateDomainSettings(
  $input: UpdateDomainSettingsInput!
  $condition: ModelDomainSettingsConditionInput
) {
  updateDomainSettings(input: $input, condition: $condition) {
    id
    createTopicAuthorityGroupPolicy {
      admin
      user
      __typename
    }
    updateTopicAuthorityGroupPolicy {
      admin
      user
      __typename
    }
    attachmentPolicy
    attachmentFileType {
      image
      movie
      pdf
      office
      __typename
    }
    downloadPolicy
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateDomainSettingsMutationVariables,
  APITypes.UpdateDomainSettingsMutation
>;
export const deleteDomainSettings = /* GraphQL */ `mutation DeleteDomainSettings(
  $input: DeleteDomainSettingsInput!
  $condition: ModelDomainSettingsConditionInput
) {
  deleteDomainSettings(input: $input, condition: $condition) {
    id
    createTopicAuthorityGroupPolicy {
      admin
      user
      __typename
    }
    updateTopicAuthorityGroupPolicy {
      admin
      user
      __typename
    }
    attachmentPolicy
    attachmentFileType {
      image
      movie
      pdf
      office
      __typename
    }
    downloadPolicy
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteDomainSettingsMutationVariables,
  APITypes.DeleteDomainSettingsMutation
>;
export const createAllowFeature = /* GraphQL */ `mutation CreateAllowFeature(
  $input: CreateAllowFeatureInput!
  $condition: ModelAllowFeatureConditionInput
) {
  createAllowFeature(input: $input, condition: $condition) {
    keyword
    releasePhase
    desc
    updatedAt
    createdAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateAllowFeatureMutationVariables,
  APITypes.CreateAllowFeatureMutation
>;
export const updateAllowFeature = /* GraphQL */ `mutation UpdateAllowFeature(
  $input: UpdateAllowFeatureInput!
  $condition: ModelAllowFeatureConditionInput
) {
  updateAllowFeature(input: $input, condition: $condition) {
    keyword
    releasePhase
    desc
    updatedAt
    createdAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateAllowFeatureMutationVariables,
  APITypes.UpdateAllowFeatureMutation
>;
export const deleteAllowFeature = /* GraphQL */ `mutation DeleteAllowFeature(
  $input: DeleteAllowFeatureInput!
  $condition: ModelAllowFeatureConditionInput
) {
  deleteAllowFeature(input: $input, condition: $condition) {
    keyword
    releasePhase
    desc
    updatedAt
    createdAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteAllowFeatureMutationVariables,
  APITypes.DeleteAllowFeatureMutation
>;
