














































import { mixins } from "vue-class-component";
import { Component, Prop, Vue } from 'vue-property-decorator';
import DirectDomainDropdown from "./DirectDomainDropBox.vue";
import IconNavigationBrand from "../icon/IconNavigatonBrand.vue";
import type { IDirectUserInfo } from "../../direct-utility";
import { createDummyDirectUser } from '../../direct-utility';
import TextProcessMixin from "../mixin/TextProcessMixin";
import { Domains } from "@/direct-access";

export type NavigationItem = { to: string, label: string, click?: string };


/**
 * ナビゲーションバー（左）
 * back       戻るボタン
 * domains    組織選択ドロップダウンボタン
 * cancel     キャンセルボタン
 * left-items 指定されたものを表示
 */
@Component({
    mixins: [
        TextProcessMixin
    ],
    components: { DirectDomainDropdown, IconNavigationBrand, }
})
export default class NavBarLeft extends mixins( Vue, TextProcessMixin ) {
    name = "nav-bar-left";

    @Prop({ default: () => "" }) readonly domainId!: string; // 組織選択ドロップボタンの現在の組織取得する用

    @Prop({ default: () => createDummyDirectUser() }) readonly user!: IDirectUserInfo;

    @Prop({ default: () => false }) readonly back!: boolean;   // true:戻るボタン
    @Prop({ default: () => false }) readonly brand!: boolean;     // true: ブランド表示
    @Prop({ default: () => false }) readonly domains!: boolean;   // true: 組織選択ドロップダウン
    @Prop({ default: () => false }) readonly cancel!: boolean;    // true: キャンセルボタン
    /** ナビゲーションの左側メニューアイテム。２つ以上でドロップダウンになります */
    @Prop({ default: () => [] }) readonly leftItems!: NavigationItem[];

    get leftEmpty(): boolean { return this.back == false && this.domains == false && this.cancel == false && this.leftItems.length == 0; }

    // スタイル
    get navBarStyle(): Record<string, unknown> {
        return {
            height: "44pt",
        }
    }

    get domainList(): Domains {
        if( this.user ) {
            // 組織名昇順にソート(カタカナ→ひらがな、小文字にして比較)
            return this.user.domains.sort((l, r) => {
                const a = this.katakanaToHiragana(l.domain_name).toLowerCase();
                const b = this.katakanaToHiragana(r.domain_name).toLowerCase();
                if (a < b) return -1;
                if (a > b) return 1;
                return 0;
            });
        }
        return [];
    }

}
