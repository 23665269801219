import { Vue } from "vue-property-decorator";

// .ts から App.vueのイベントモーダルへの橋渡しの役割を担う

/** イベント管理クラス */
export class EventManager {
    private static $root?: Vue;

    public static init( $root: Vue ): void {
        this.$root = $root;
    }

    // 無料版の制限に関するアラート
    public static freeAlertEvent(): void {
        this.$root?.$emit("free-alert");
    }
}