import { render, staticRenderFns } from "./IconLike.vue?vue&type=template&id=fdf20474&scoped=true&"
import script from "./IconLike.vue?vue&type=script&lang=js&"
export * from "./IconLike.vue?vue&type=script&lang=js&"
import style0 from "./IconLike.vue?vue&type=style&index=0&id=fdf20474&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fdf20474",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src2820442577/src/dxflow-forum/packages/open-channel/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('fdf20474')) {
      api.createRecord('fdf20474', component.options)
    } else {
      api.reload('fdf20474', component.options)
    }
    module.hot.accept("./IconLike.vue?vue&type=template&id=fdf20474&scoped=true&", function () {
      api.rerender('fdf20474', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/icon/IconLike.vue"
export default component.exports