import DirectUtility from "./direct-utility";
import axios from "axios";


class ProfileIconTask {
    listeners: ((url: string)=>void)[] = [];
    private iconTable: Record<string, string>;

    public constructor( iconTable: Record<string, string> ) {
        this.iconTable = iconTable;
    }

    public async task( profileUrl: string ): Promise<string> {
        let blobUrl;
        try {
            // directのS3署名付きURLを取得
            // これは期限付きなのでキャッシュしてもしょうがない
            const url = ( await DirectUtility.getDirectAuthenticatedImageUrl(profileUrl) ) || "";

            // 画像データをBLOBに変更し、BLOB URL をキャッシュする
            const response = await axios.get( url, { responseType: 'arraybuffer'} );
            const buffer = response.data;
            const blob = new Blob([buffer], { type: response.headers["content-type"] } );
            blobUrl = URL.createObjectURL( blob );
            this.iconTable[ profileUrl ] = blobUrl;
            return blobUrl;
        } catch( error ) {
            console.error( error );
            this.iconTable[ profileUrl ] = "";  //  エラー
            return "";
        }
    }
}

/**
 * プロフィールアイコンの管理
 */
export class ProfileIconManager {

    /**
     * プロフィール画像のテーブル
     * profileIconUrl -> 署名付きS3 URL に変換する
     *
     * 署名付き S3 URL は有効期限付きのため、いつまでも使えるわけではない
     * （ブラウザからアクセスなら内部キャッシュを見るため大丈夫？？）
     * blobに変換するのが本来正しい
     */
    private static iconTable: Record<string, string> = {};

    /**
     * プロフィール画像取得を行うタスクテーブル
     * ここに載っている場合は纏める
     */
    private static taskTable: Record<string, ProfileIconTask> = {};

    /**
     * プロフィール画像を取得する
     * まだ未取得の場合は空文字列を返し、取得後にcallbackに返される
     * @param profileUrl プロフィール画像URL
     * @param callback 画像がキャッシュされてない場合は取得後にcallbackでURLが返される
     * @returns プロフィール画像URL。キャッシュされてない場合は空文字列
     */
    public static getProfileIconUrl( profileUrl: string|undefined, callback: ( url: string) => void ): string {
        // 設定されてない
        if( !profileUrl ) return "";

        // キャッシュされてる
        const blobUrl = this.iconTable[ profileUrl ];
        if( blobUrl !== undefined ) return blobUrl;

        // 相対パス指定になっている
        if( profileUrl.startsWith("http") == false ) return profileUrl;

        let task = this.taskTable[ profileUrl ];
        if( task !== undefined ) {
            task.listeners.push( callback );
        } else {
            task = new ProfileIconTask( this.iconTable );
            this.taskTable[ profileUrl ] = task;
            task.listeners.push( callback );
            task.task( profileUrl ).then( url => {
                task.listeners.forEach( cb => {
                    cb( url );
                })
                delete this.taskTable[ profileUrl ];
            })
        }
        return "";  // タスク実行中なので、とりあえず空を返す
    }
}
