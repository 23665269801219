/**
 * APIサーバへのアクセス用
 */

import axios, { AxiosResponse } from "axios";
import { DirectDomainMembersType } from "./direct-restapi-types";
import { Category, Topic, Message, Comment } from "@/model";
import { IconReaction, Topic as DbTopic, Category as DbCategory, Message as DbMessage , Comment as DbComment, Department as DbDepartment, Department, IconReaction as DbReaction } from "./API";
import { ServerUserInfo } from "./aws-config";
import { SolutionLinkResponse } from "./model/solution";
import { EventManager } from "./events/event-manager";
import sentry from "./sentry";

export type Domains = Direct.GetDomains;

// API-GWへの接続設定
const PRODUCT_BASE      = process.env.VUE_APP_PRODUCT_BASE || "/";
const AUTH_SERVER_URL   = process.env.VUE_APP_AUTH_SERVER_URL || "http://localhost:3000/";

const ServerApi = {
    userData: new URL( `${PRODUCT_BASE}userData`, AUTH_SERVER_URL ).toString(),
    flow: new URL( `${PRODUCT_BASE}flow`, AUTH_SERVER_URL ).toString(),
    domainMembers: ( domainId: string, offset?: number, limit?: number ) => {
        const url = new URL( `${PRODUCT_BASE}domain-members`, AUTH_SERVER_URL );
        const params = url.searchParams;
        params.append( "domainId", domainId );
        if( offset ) params.append( "offset", String( offset ) );
        if( limit  ) params.append( "limit",  String( limit ) );
        return url.toString();
    },
    listCategories: ( domainId: string ) => {
        const url = new URL( `${PRODUCT_BASE}list-categories`, AUTH_SERVER_URL );
        const params = url.searchParams;
        params.append( "domain_id", domainId );
        return url.toString();
    },
    topicListByAcl: (domainId: string) => {
        const url = new URL( `${PRODUCT_BASE}list-topics-by-acl`, AUTH_SERVER_URL );
        const params = url.searchParams;
        params.append( "domain_id", domainId );
        return url.toString();
    },
    listMessages: (domainId: string, topicId: string, messageId?: string) => {
        const url = new URL( `${PRODUCT_BASE}list-messages`, AUTH_SERVER_URL );
        const params = url.searchParams;
        params.append( "domain_id", domainId );
        params.append( "topic_id", topicId );
        if( messageId ) {
            params.append( "message_id", messageId );
        }
        return url.toString();
    },
    listComments: (domainId: string, topicId: string, messageId?: string) => {
        const url = new URL( `${PRODUCT_BASE}list-comments`, AUTH_SERVER_URL );
        const params = url.searchParams;
        params.append( "domain_id", domainId );
        params.append( "topic_id", topicId );
        if( messageId ) {
            params.append( "message_id", messageId );
        }
        return url.toString();
    },
    listReactions: ( domainId: string, topicId?: string ) => {
        const url = new URL( `${PRODUCT_BASE}list-reactions`, AUTH_SERVER_URL );
        const params = url.searchParams;
        params.append( "domain_id", domainId );
        if( topicId ) {
            params.append( "topic_id", topicId );
        }
        return url.toString();
    },
    listDepartments: ( domainId: string ) => {
        const url = new URL( `${PRODUCT_BASE}list-departments`, AUTH_SERVER_URL );
        const params = url.searchParams;
        params.append( "domain_id", domainId );
        return url.toString();
    },
    listApps: (domainId: string) => {
        const url = new URL( `${PRODUCT_BASE}list-apps`, AUTH_SERVER_URL );
        const params = url.searchParams;
        params.append( "domainId", domainId );
        return url.toString();
    },
    listDomains: () => {
        const url = new URL( `${PRODUCT_BASE}list-domains`, AUTH_SERVER_URL );
        return url.toString();
    },
    upsertDepartments: new URL( `${PRODUCT_BASE}upsert-departments`, AUTH_SERVER_URL ).toString(),
    createCategory: new URL( `${PRODUCT_BASE}create-category`, AUTH_SERVER_URL ).toString(),
    updateCategory: new URL( `${PRODUCT_BASE}update-category`, AUTH_SERVER_URL ).toString(),
    createTopic: new URL( `${PRODUCT_BASE}create-topic`, AUTH_SERVER_URL ).toString(),
    updateTopic: new URL( `${PRODUCT_BASE}update-topic`, AUTH_SERVER_URL ).toString(),
    createMessage: new URL( `${PRODUCT_BASE}create-message`, AUTH_SERVER_URL ).toString(),
    updateMessage: new URL( `${PRODUCT_BASE}update-message`, AUTH_SERVER_URL ).toString(),
    createComment: new URL( `${PRODUCT_BASE}create-comment`, AUTH_SERVER_URL ).toString(),
    updateComment: new URL( `${PRODUCT_BASE}update-comment`, AUTH_SERVER_URL ).toString(),
    upsertReaction: new URL( `${PRODUCT_BASE}upsert-reaction`, AUTH_SERVER_URL ).toString(),
    logging: new URL( `${PRODUCT_BASE}logging`, AUTH_SERVER_URL ).toString(),
    notifications: new URL( `${PRODUCT_BASE}notifications`, AUTH_SERVER_URL ).toString(),
    topicMessageCount: new URL( `${PRODUCT_BASE}topic/update-message-count`, AUTH_SERVER_URL ).toString(),

    error: new URL( `${PRODUCT_BASE}debug-sentry`, AUTH_SERVER_URL ).toString(),
}

export class ServerApiAccess {

    public constructor() {
        // 空
    }

    /**
     * GET系 API にアクセス
     * @param api API-GW REST API endpoint
     */
    private async getApi<T>( api: string ): Promise< AxiosResponse<T|undefined>> {
        const response = await axios.get( api, { withCredentials: true } );
        return response;
    }

    /**
     * POST系 API にアクセス
     * @param api API-GW REST API endpoint
     */
    private async postApi<T>( api: string, param: any ): Promise<T|undefined> {
        const response = await axios.post( api, param, { withCredentials: true } );
        return response.data as T;
    }

    /**
     * PUT系 API にアクセス
     * @param api API-GW REST API endpoint
     * @param param パラメーター
     */
    private async putApi<T>( api: string, param: any ): Promise<T|undefined> {
        const response = await axios.put( api, param, { withCredentials: true } );
        return response.data as T;
    }

    /**
     * /userData にアクセスして、自分のユーザー情報を取得します
     * @returns ユーザー情報。number: 401は未ログインエラー。undefined: その他のエラー
     */
    public async getUserData(): Promise<ServerUserInfo|number|undefined> {
        try {
            const result = await this.getApi<{user:ServerUserInfo}>( ServerApi.userData );
            if( result.status == 200 && result.data?.user ) {
                const user = result.data.user as ServerUserInfo;
                console.log("★ServerUserInfo:%O", JSON.stringify(user) )
                return user;
            } else if ( result.status == 401 ) {
                // 認証してくださいエラー
                console.error( "error(401). not authorized" );
                return result.status;
            } else if ( result.status == 200 && ( result.data as any ) == "Request failed with status code 400" ) {
                // refresh token の更新エラー
                console.error( "error(200) and refresh token error" );
                return 401;
            } else {
                console.error(`error code:${result.status} data:${JSON.stringify(result.data)}` );
                return undefined;
            }
        } catch( err: any ) {
            const status = err?.response?.status;
            console.log("★response:%O status:%O", err.response, status)
            if( status == 401 ) {
                // 認証してくださいエラー
                console.error( "error(401). not authorized" );
                return status;
            } else {
                // セッションが無い
                console.error(`error code:${status} data:${JSON.stringify(err)}` );
                return undefined;
            }
        }
    }

    /**
     * 組織内メンバー一覧を取得する
     * @param domainId 組織ID
     * @param offset オフセット
     * @param limit リミット
     * @returns
     */
    public async getDomainMembers( domainId: string, offset: number = 0, limit: number = 20, ): Promise<DirectDomainMembersType|number|undefined> {
        try {
            const result = await this.getApi<DirectDomainMembersType>( ServerApi.domainMembers( domainId, offset, limit ) );
            if( result.status == 200 && result.data?.contents ) {
                return result.data;
            } else if( result.status == 401 ) {
                // 認証してくださいエラー
                return result.status;
            } else if ( result.status == 200 && ( result.data as any ) == "Request failed with status code 400" ) {
                // refresh token の更新エラー
                console.error( "error(200) and refresh token error" );
                return 401;
            } else if ( result.status == 200 && ( result.data as any ).startsWith("Unexpected") ) {
                // direct REST API サーバー自体のエラー
                console.error( "error(200) and REST API server error:", result.data )
                return 500;
            } else {
                ServerApiAccess.errorlog( "getDomainMembers", result.status, result.data )
                return undefined;
            }
        } catch( err: any ) {
            const status = err?.response?.status;
            console.log("★response:%O status:%O", err.response, status)
            if( status == 401 ) {
                // 認証してくださいエラー
                console.error( "error(401). not authorized" );
                return status;
            } else {
                // セッションが無い
                console.error(`error code:${status} data:${JSON.stringify(err)}` );
                return undefined;
            }
        }
    }

    /**
     * 話題の投稿数を更新する
     * @param domainId 組織ID
     * @param topicId 話題ID
     * @param updateMessageCount true: 話題の投稿数を更新する
     */
    public async putTopicMessageCount( domainId: string, topicId: string, updateMessageCount: boolean ): Promise<void> {
        try {
            const result = await this.putApi<any>( ServerApi.topicMessageCount, { domainId: domainId, topicId: topicId, updateMessageCount: updateMessageCount } );
            if (result) { // エラーメッセージが返された場合
                ServerApiAccess.errorlog( "putTopicMessageCount", result )
            }
        } catch( err: any ) {
            ServerApiAccess.errorlog( "putTopicMessageCount", err?.response?.status, err )
        }
    }

    /**
     * 話題取得
     * @param domainId
     * @returns
     */
    public async getTopics( domainId: string ): Promise<{ topics: DbTopic[], totalCount: number }|number|undefined> {
        try {
            const result = await this.getApi<{ topics: DbTopic[], totalCount: number }>( ServerApi.topicListByAcl(domainId) );
            if( result.status == 200 && result.data && typeof result.data !== 'string' ) {
                return result.data;
            } else if( result.status == 401 ) {
                // 認証してくださいエラー
                return result.status;
            } else {
                // セッションが無い
                console.error(`error code:${result.status}}` );
                return undefined;
            }
        } catch( err: any ) {
            const status = err?.response?.status;
            console.log("★response:%O status:%O", err.response, status)
            if( status == 401 ) {
                // 認証してくださいエラー
                console.error( "error(401). not authorized" );
                return status;
            } else {
                // セッションが無い
                console.error(`error code:${status} data:${JSON.stringify(err)}` );
                return undefined;
            }
        }
    }

    // API共通処理
    private async _apiAccess<T>( params: string ): Promise<T|undefined|number> {
        try {
            const result = await this.getApi<T>( params );
            if( result.status == 200 && result.data ) {
                return result.data;
            } else if( result.status == 401 ) {
                // 認証してくださいエラー
                return result.status;
            } else {
                // セッションが無い
                ServerApiAccess.errorlog( "common", result.status )
                return undefined;
            }
        } catch ( err: any ) {
            const status = err?.response?.status;
            console.log("★response:%O status:%O", err.response, status)
            if( status == 401 ) {
                // 認証してくださいエラー
                console.error( "error(401). not authorized" );
                return status;
            } else {
                // セッションが無い
                ServerApiAccess.errorlog( "common", status, err )
                return undefined;
            }
        }
    }

    /** directのアプリ一覧を取得する */
    public async listApps( domainId: string ): Promise<SolutionLinkResponse|undefined|number> {
        const params = ServerApi.listApps(domainId);
        return this._apiAccess( params )
    }

    /** 部署の更新を行う */
    public async upsertDepartments( domainId: string ): Promise<DbDepartment[]|undefined> {
        try {
            const result = await this.putApi<DbDepartment[]>( ServerApi.upsertDepartments, { domainId: domainId } );
            console.log("results", result);
            if( !result || typeof result === "string" ) {
                ServerApiAccess.errorlog( "upsertDepartments", result )
                return undefined;
            } else {
                return result;
            }
        } catch( err: any ) {
            ServerApiAccess.errorlog( "upsertDepartments", err?.response?.status, err )
            return undefined;
        }
    }

    /** 部署情報を取得 */
    public async listDepartments( domainId: string ): Promise<Department[]> {
        try {
            const result = await this.getApi<Department[]>( ServerApi.listDepartments(domainId) );
            if( result.status == 200 && result.data && typeof result.data !== 'string' ) {
                return result.data;
            } else if( result.status == 401 ) {
                // 認証してくださいエラー
                return [];
            } else if( result.status == 403 ) {
                // 権限がない
                console.error( "error(403) access denied." );
                return [];
            } else {
                // セッションが無い
                console.error(`error code:${result}`);
                return [];
            }
        } catch( err: any ) {
            const status = err?.response?.status;
            console.log("★response:%O status:%O", err.response, status)
            if( status == 401 ) {
                // 認証してくださいエラー
                console.error( "error(401). not authorized" );
                return [];
            } else if( status == 403 ) {
                // 権限がない
                console.error( "error(403) access denied." );
                return [];
            } else {
                // セッションが無い
                console.error(`error code:${status} data:${JSON.stringify(err)}` );
                return [];
            }
        }
    }

    /** directの組織情報を取得 */
    public async listDomains(): Promise<Domains|undefined|number> {
        const params = ServerApi.listDomains();
        return this._apiAccess(params);
    }

    /**
     * カテゴリー作成
     * @param category
     * @returns
     */
    public async createCategory( category: Category ): Promise<DbCategory|undefined> {
        try {
            const result = await this.putApi<DbCategory>( ServerApi.createCategory, category );
            console.log("results", result);
            if( !result || typeof result === "string" ) {
                ServerApiAccess.errorlog( "createCategory", result )
                return undefined;
            } else {
                return result;
            }
        } catch( err: any ) {
            ServerApiAccess.errorlog( "createCategory", err?.response?.status, err )
            return undefined;
        }
    }

    /**
     * カテゴリー一覧取得
     * @param domainId
     * @returns
     */
    public async listCategories( domainId: string ): Promise<DbCategory[]|undefined> {
        try {
            const result = await this.getApi<DbCategory[]>( ServerApi.listCategories(domainId) );
            if( result.status == 200 && result.data && typeof result.data !== 'string' ) {
                return result.data;
            } else if( result.status == 401 ) {
                // 認証してくださいエラー
                return undefined;
            } else {
                // セッションが無い
                ServerApiAccess.errorlog( "listCategories", result.status )
                return undefined;
            }
        } catch( err: any ) {
            ServerApiAccess.errorlog( "listCategories", err?.response?.status, err )
            return undefined;
        }
    }

    /**
     * カテゴリー編集
     * @param category
     * @returns
     */
    public async updateCategory( category: Partial<Category> ): Promise<DbCategory|undefined> {
        try {
            const result = await this.putApi<DbCategory>( ServerApi.updateCategory, category );
            if( !result || typeof result === "string" ) {
                ServerApiAccess.errorlog( "updateCategory", result )
                return undefined;
            } else {
                return result;
            }
        } catch( err: any ) {
            ServerApiAccess.errorlog( "updateCategory", err?.response?.status, err )
            return undefined;
        }
    }

    /**
     * 話題作成
     * @param topic
     * @returns
     */
    public async createTopic( topic: Topic ): Promise<DbTopic|undefined> {
        try {
            const result = await this.putApi<DbTopic>( ServerApi.createTopic, topic );
            if( !result || typeof result === "string" ) {
                // 無料版の制限エラー
                if( typeof result === "string" && (result as string).match(/exceeded limit/) ) {
                    EventManager.freeAlertEvent();
                }
                ServerApiAccess.errorlog( "createTopic", result )
                return undefined;
            } else {
                return result;
            }
        } catch( err: any ) {
            ServerApiAccess.errorlog( "createTopic", err?.response?.status, err )
            return undefined;
        }
    }

    /**
     * 話題更新
     * @param topic
     * @returns
     */
    public async updateTopic( topic: Partial<Topic> ): Promise<DbTopic|undefined> {
        try {
            const result = await this.putApi<DbTopic>( ServerApi.updateTopic, topic );
            if( !result || typeof result === "string" ) {
                ServerApiAccess.errorlog( "updateTopic", result )
                return undefined;
            } else {
                return result;
            }
        } catch( err: any ) {
            ServerApiAccess.errorlog( "updateTopic", err?.response?.status, err )
            return undefined;
        }
    }

    /**
     * 投稿作成
     * @param message
     * @returns
     */
    public async createMessage( message: Message ): Promise<DbMessage|undefined> {
        try {
            const result = await this.putApi<DbMessage>( ServerApi.createMessage, message );
            if( !result || typeof result === "string" ) {
                // 無料版の制限エラー
                if( typeof result === "string" && (result as string).match(/exceeded limit/) ) {
                    EventManager.freeAlertEvent();
                }
                ServerApiAccess.errorlog( "createMessage", result )
                return undefined;
            } else {
                return result;
            }
        } catch( err: any ) {
            ServerApiAccess.errorlog( "createMessage", err?.response?.status, err )
            return undefined;
        }
    }

    /**
     * 投稿編集
     * @param message
     * @returns
     */
    public async updateMessage( message: Partial<Message> ): Promise<DbMessage|undefined> {
        try {
            const result = await this.putApi<DbMessage>( ServerApi.updateMessage, message );
            if( !result || typeof result === "string" ) {
                ServerApiAccess.errorlog( "updateMessage", result )
                return undefined;
            } else {
                return result;
            }
        } catch( err: any ) {
            ServerApiAccess.errorlog( "updateMessage", err?.response?.status, err )
            return undefined;
        }
    }


    /**
     * 投稿一覧取得
     * @param domainId
     * @param topicId
     * @param messageId
     * @returns
     */
    public async listMessages( domainId: string, topicId: string, messageId?: string ): Promise<DbMessage[]|undefined> {
        try {
            const result = await this.getApi<DbMessage[]>( ServerApi.listMessages(domainId, topicId, messageId) );
            if( result.status == 200 && result.data && typeof result.data !== 'string' ) {
                return result.data;
            } else if( result.status == 401 ) {
                // 認証してくださいエラー
                return undefined;
            } else {
                // セッションが無い
                ServerApiAccess.errorlog( "listMessages", result.status )
                return undefined;
            }
        } catch( err: any ) {
            ServerApiAccess.errorlog( "listMessages", err?.response?.status, err )
            return undefined;
        }
    }

    /**
     * コメント作成
     * @param comment
     * @returns
     */
    public async createComment( comment: Comment ): Promise<DbComment|undefined> {
        try {
            const result = await this.putApi<DbComment>( ServerApi.createComment, comment );
            if( !result || typeof result === "string" ) {
                ServerApiAccess.errorlog( "createComment", result )
                return undefined;
            } else {
                return result;
            }
        } catch( err: any ) {
            ServerApiAccess.errorlog( "createComment", err?.response?.status, err )
            return undefined;
        }
    }

    /**
     * コメント更新
     * @param comment
     * @returns
     */
    public async updateComment( comment: Partial<Comment> ): Promise<DbComment|undefined> {
        try {
            const result = await this.putApi<DbComment>( ServerApi.updateComment, comment );
            if( !result || typeof result === "string" ) {
                ServerApiAccess.errorlog( "updateComment", result )
                return undefined;
            } else {
                return result;
            }
        } catch( err: any ) {
            ServerApiAccess.errorlog( "updateComment", err?.response?.status, err )
            return undefined;
        }
    }

    /**
     * コメント一覧取得
     * @param domainId
     * @param topicId
     * @param messageId
     * @returns
     */
    public async listComments( domainId: string, topicId: string, messageId?: string ): Promise<DbComment[]|undefined> {
        try {
            const result = await this.getApi<DbComment[]>( ServerApi.listComments(domainId, topicId, messageId) );
            if( result.status == 200 && result.data && typeof result.data !== 'string' ) {
                return result.data;
            } else if( result.status == 401 ) {
                // 認証してくださいエラー
                return undefined;
            } else {
                // セッションが無い
                ServerApiAccess.errorlog( "listComments", result.status )
                return undefined;
            }
        } catch( err: any ) {
            ServerApiAccess.errorlog( "listComments", err?.response?.status, err )
            return undefined;
        }
    }

    /**
     * リアクション作成/更新
     * @param reaction
     * @param value リアクションのチェック値
     * @returns
     */
    public async upsertReaction( reaction: IconReaction, value: boolean ): Promise<DbReaction|undefined> {
        try {
            const result = await this.putApi<DbReaction>( ServerApi.upsertReaction, { ...reaction, value: value } );
            if( !result || typeof result === "string" ) {
                console.error("error:%O", result);
                return undefined;
            } else {
                return result;
            }
        } catch( err: any ) {
            ServerApiAccess.errorlog( "upsertReaction", err?.response?.status, err )
            return undefined;
        }
    }

    /**
     * リアクション一覧取得
     * @param domainId
     * @param topicId
     * @returns
     */
    public async listReactions( domainId: string, topicId?: string ): Promise<{ topicReactions: DbReaction[], messageReactions: DbReaction[], commentReactions: DbReaction[] }|undefined> {
        try {
            const result = await this.getApi<{ topicReactions: DbReaction[], messageReactions: DbReaction[], commentReactions: DbReaction[] }>( ServerApi.listReactions(domainId, topicId) );
            if( result.status == 200 && result.data && typeof result.data !== 'string' ) {
                return result.data;
            } else if( result.status == 401 ) {
                // 認証してくださいエラー
                return undefined;
            } else {
                // セッションが無い
                console.error("error code:%s data:%O", status );
                return undefined;
            }
        } catch ( err: any ) {
            ServerApiAccess.errorlog( "listReactions", err?.response?.status, err )
            return undefined;
        }
    }

    /** ロガーに記録する */
    public static logging( command: string, params: any ): void {
        try {
            const api = new ServerApiAccess();
            api.postApi( ServerApi.logging, {
                command,
                params,
            }).catch( ( err ) => {
                // loggingに対するエラー処理は行わない
                console.error( "error logging:", err )
            })
        } catch( err ) {
            console.error( "error logging:", err );
        }
    }

    private static errorlog( label: string, status: unknown, err?: any ): void {
        let msg = `[${label}] error code:${status}`;
        if( err ) msg += `data:${JSON.stringify( err )}`
        console.error( msg )
        sentry.sendSentryError( new Error( msg ) )
    }

    public static debugSentry(): void {
        const api = new ServerApiAccess();
        api.getApi( ServerApi.error );
    }

}
