

















































































import { Component, Prop, Vue } from 'vue-property-decorator';
import ProfileIcon from "./ProfileIcon.vue";
import { createDummyDirectUser, IDirectUserInfo } from '../direct-utility';
import NavBarLeft from "./navigation-bar/NavBarLeft.vue";
import NavBarRight from "./navigation-bar/NavBarRight.vue";
import SearchForm from "./SearchForm.vue";
import { directAppUrl, AppKeys, appAvailable } from "../direct-app-config";
import './navigation-bar.scss'
import HistoryUtility from "../history";
import { AppLinkItem, getSolutionItems } from './solutions/';
import { Domains } from '@/direct-access';

export type NavigationItem = { to: string, label: string, click?: string };


/**
 * ナビゲーションバー
 *
 * ■左側
 * back       戻るボタン
 * domains    組織選択ドロップダウンボタン
 * cancel     キャンセルボタン
 * left-items 指定されたものを表示
 *
 * ■右側
 * ok         OKボタン
 * complete   完了ボタン
 * items      指定メニュー
 * preset     プリセットメニュー（ユーザーアイコンから始まるメニュー）
 * close      ✕ボタン
 *
 */
@Component({
    components: { ProfileIcon, NavBarLeft, NavBarRight, SearchForm, }
})
export default class NavigationBar extends Vue {
    name = "navigation-bar";

    user: IDirectUserInfo = createDummyDirectUser();

    isInvalidDomainId: boolean = false;

    @Prop({ default: () => "" }) readonly title!: string;       // タイトル
    @Prop({ default: () => "" }) readonly subTitle!: string;    // サブタイトル
    @Prop({ default: () => "" }) readonly domainId!: string;    // 選択されたdomainId
    @Prop({ default: () => false }) readonly apps!: boolean;    // true: directアプリへのリンクを出す

    @Prop({ default: () => false }) readonly back!: boolean;   // true: 組織選択ドロップダウン
    @Prop({ default: () => false }) readonly brand!: boolean;   // true: ブランドアイコン表示
    @Prop({ default: () => false }) readonly domains!: boolean;   // true: 組織選択ドロップダウン
    @Prop({ default: () => false }) readonly cancel!: boolean;    // true: キャンセルボタン
    /** ナビゲーションの左側メニューアイテム。２つ以上でドロップダウンになります */
    @Prop({ default: () => [] }) readonly leftItems!: NavigationItem[];

    /** ナビゲーションの右側メニューアイテム */
    @Prop({ default: () => false }) readonly ok!: boolean;
    @Prop({ default: () => false }) readonly complete!: boolean;
    @Prop({ default: () => [] }) readonly items!: NavigationItem[];
    @Prop({ default: () => false }) readonly preset!: boolean;
    @Prop({ default: () => false }) readonly close!: boolean;
    @Prop({ default: () => false }) readonly appClose!: boolean;    //!< アプリを閉じるボタン(iOS/Androidで利用)

    @Prop({ default: () => false }) readonly search!: boolean;
    @Prop({ default: () => false }) readonly searching!: boolean;   //!< true: 検索中

    @Prop({ default: () => true }) readonly fetchUserInfo!: boolean;    // true: ユーザー情報の取得を行う

    // 外部から設定可能にする
    @Prop({ default: undefined }) readonly userSpecified!: IDirectUserInfo;

    get leftEmpty(): boolean { return this.back == false && this.domains == false && this.cancel == false && this.leftItems.length == 0; }
    get empty(): boolean     { return this.ok == false && this.complete == false && this.items.length == 0 && this.preset == false && this.close == false && this.search == false; }

    // ナビゲーションバーの基礎class
    get navbarClass(): string {
        if( this.search ) {
            return "navbar-base-search";
        } else {
            return "navbar-base";
        }
    }

    get userId(): string    { return this.user ? this.user.userId : "" }
    get userName(): string  { return this.user ? this.user.name : "" }
    get userImage(): string { return this.user ? this.user.image : "" }

    get domainList(): Domains {
        if( this.user ) return this.user.domains;
        return [];
    }

    get topicId(): string|undefined {
        if( !this.$route ) return undefined;
        const topicId = this.$route.params["topicId"];
        return topicId;
    }

    // ソリューション一覧
    get solutionItems(): AppLinkItem[] { return getSolutionItems( this.domainId, this.$store ) }

    // direct、タスク、スケジュールへのリンク
    get talkUrl(): string { return directAppUrl( AppKeys.TALK, this.domainId, this.$store ); }
    get taskUrl(): string { return directAppUrl( AppKeys.TASK, this.domainId, this.$store ); }
    get scheduleUrl(): string { return directAppUrl( AppKeys.SCHEDULE, this.domainId, this.$store ); }

    // 各アプリが有効かどうか
    // appEnable( app: AppKeys ): boolean { return appAvailable( app, this.$store ) }
    appEnable( app: AppKeys ): boolean {
        return appAvailable( app, this.$store )
    }

    async created(): Promise<void> {
        // ユーザー情報の取得を行わない
        if( this.fetchUserInfo == false ) return;

        const domainId = this.$store.getters["domainId"];
        await this.$store.dispatch("fetchDirectInfo", { domainId: domainId });
        if( this.$store.getters["domains/isEnable"]( domainId ) ) {
            // 利用不可が表示されていた場合、無効化する
            this.$root.$emit("on-not-allowed-modal", { show: false });
        } else {
            // 掲示板が無効設定されている
            this.$root.$emit("on-not-allowed-modal", { show: true });
        }
    }

    updated(): void {
        // ユーザー情報が外部から設定される
        if( this.userSpecified ) {
            this.user = this.userSpecified;
            return;
        }

        const loginUser = this.$store.getters["users/getLoginUser"];
        if( loginUser &&  typeof loginUser !== "number" ) {
            this.user = loginUser;
        }
    }

    mounted(): void {
        this.$root.$on( 'on-overlay-with-search-window', ( isInvalid: boolean ) => {
            this.isInvalidDomainId = isInvalid;
        })
    }

    /** 戻る */
    doBack(): void {
        if( this.$router ) {
            if( HistoryUtility.isTopHistory() ) {
                const path = this.$router.currentRoute.path;
                if( path == "/login-error-oidc" ) {
                    this.doAppClose();
                    return;
                }
                const name = this.$router.currentRoute.name;
                switch( name ) {
                case "message-list":    // 現在投稿一覧にいる場合は、話題一覧に遷移する
                    this.$router.replace(`/${this.domainId}`)
                    break;
                case "topic-list":
                    this.doAppClose();  // 現在話題一覧にいる場合は、アプリを閉じる(話題一覧ヘッダーにbackボタンがあるのはモバイルのみ)
                    break;
                default:                // その他の場合、ヒストリーに沿う
                    this.$router.go(-1);// go(-1)がOIDC認可画面でも判定する方法は存在しないためブロックできない
                    break;
                }
            } else {
                this.$router.go( -1 );  // 履歴を戻すだけ
            }
        }
    }

    /** キャンセル */
    doCancel(): void { this.$emit("on-cancel"); }

    /** 閉じる */
    doClose(): void { this.$emit("on-close"); }

    /** OK */
    doOk(): void { this.$emit("on-ok"); }

    /** 完了 */
    doComplete(): void { this.$emit("on-complete"); }

    doAppClose(): void {
        this.$root.$emit("on-app-close");
    }

    doMenuOpen(): void {
        const selections = [ { message: '同期', action: ()=>{ this.$root.$emit('sync-data-alert'); } } ];
        this.$root.$emit('open-action-sheet', selections);
    }
}
