





















































































































































































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
// import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";
import S3AccessUtility from './s3-acceess-utility';
import { v4 as uuidv4 } from "uuid";
import { Attachment } from '../model';
import LoadingAttachment from "./loading/LoadingAttachment.vue";
import CustomButton from './button/CustomButon.vue';
import { getFileType, getDefaultIcon } from "./file-utility";
import { ClientType } from '@/store/domain-store';
import { DownloadDeviceTypes } from '@/suppport-attachment-types';

// Vue.use(BootstrapVue);
// Vue.use(BootstrapVueIcons);

const PDF_DEFAULT_ICON = "/img/icon/icon_file_pdf.svg"

@Component({
    components: {
        PreviewPdfView: () => import( /* webpackChunkName: "preview-pdf-view" */ "./preview_pdf/PreviewPdfView.vue"),
        LoadingAttachment,
        CustomButton,
    }
})
export default class AttachmentImg extends Vue {
    name: string ="attachment-img";

    width: string = "0";
    height: string = "0";

    path: string = '';
    mimeType: string = "";
    id: string = '';

    loading = true;         //!< true ロード状態

    pdfFront = false;       //!< true: PDF Viewer で表示
    movieFront = false;     //!< true: Movieを表示

    errorCounter = 10;      //!< ファイル読み込み時のエラーリトライ回数
    errorRetrySecond = 10;  //!< エラーリトライ回数の間隔(秒)

    thumbnailPath: string = PDF_DEFAULT_ICON

    @Prop({ default: undefined })
    readonly image!: Attachment;

    @Prop({ default: { messageId: '', commentId: '' } })
    readonly param!: { messageId: string, commentId: string };

    // true: PhotoStreamから呼ばれている
    @Prop({ default: false })
    readonly photostream!: boolean;

    // ダウンロード可能か
    @Prop({ default: false })
    readonly downloadable!: boolean;

    // モバイルかの判定
    @Prop({ default: false })
    readonly isMobile!: boolean;

    // 編集で画像が変わった時に発火
    @Watch('image')
    async onChnageImage() {
        await this.setImagePath();
    }

    @Prop({ default: false, required: false })
    readonly clientType!: ClientType;

    @Prop({ default: () => ({ web: true, ios: true, android: true, desktop: true }), required: false })
    readonly allowSaveAttachments!: DownloadDeviceTypes;

    get attachmentBase(): Record<string, boolean> {
        return {
            "d-flex": true,
            "loading": this.loading,
            // "loading": true,
            "attachment-container": true,
            "w-100": true,
        }
    }

    get imagePath(): string {
        return this.path || this.image.url;
    }

    // true: PDF
    get isPdf(): boolean { return getFileType(this.image) === "pdf"; }

    // true: Movie
    get isMovie(): boolean { return getFileType(this.image) === "video"; }

    // true: Office
    get isOffice(): boolean { return getFileType(this.image) === "office"; }

    // true: 画像ロードエラー
    get isLoadError(): boolean { return this.errorCounter < 0; }

    get allowDownload(): boolean {
        if( !this.downloadable ) {
            return false;
        } else {
            if( this.isMobile ) {
                // ＊モバイル版ではダウンロードを規制
                return this.allowFeature("mobile-download")
            } else {
                return true;
            }
        }
    }

    get imageFileName(): string { return this.fileName( this.image.url ); }

    get isPdfThumbnail(): boolean {
        return this.thumbnailPath !== PDF_DEFAULT_ICON
    }

    get officeThumnail(): string {
        return getDefaultIcon(this.image);
    }

    get downloadNote(): string {
        const createNote = (): string => {
            if( this.allowSaveAttachments.web || this.allowSaveAttachments.desktop ) {
                return `※ダウンロードは、${this.allowSaveAttachments.web ? "WindowsまたはMacのブラウザ":""}${this.allowSaveAttachments.web && this.allowSaveAttachments.desktop  ? "・":""}${this.allowSaveAttachments.desktop ? "デスクトップアプリ":""}で対応しております。`;
            } else {
                return "";
            }
        }
        if( this.clientType === "android" || this.clientType === "ios" ) {
            if( this.clientType === "ios" ) { // iOSの場合
                if( this.allowSaveAttachments.ios ) {
                    // iOSのダウンロードが許可されているならば、説明文なし
                    return "";
                } else {
                   return createNote();
                }
            } else { // Androidの場合
                // Androidはダウンロードが許可されていないので、説明文あり
                return createNote();
            }
        } else {
            return "";
        }
    }

    async created (): Promise<void> {
        window.addEventListener('resize', this.adjustSize);
        this.id = uuidv4();
        await this.setImagePath();
    }

    beforeDestory(): void {
        window.removeEventListener('resize', this.adjustSize);
    }

    adjustSize(): void {
        const ref = this.$refs.attachment as HTMLImageElement;
        if(ref) {
            this.width = String(ref.width);
            this.height = String(ref.height);
        }
    }

    setDefaultIcon() {
        this.thumbnailPath = PDF_DEFAULT_ICON
    }

    // S3リソースの場合は、URL解決とMIME-TYPE解決を行う
    private async setImagePath(): Promise<void> {
        try {
            const s3AccessUtility = S3AccessUtility.getInstance();
            if (this.image.mime === "application/pdf") {
                this.thumbnailPath = String(await s3AccessUtility.getFile(`${this.image.url}_tmb.jpg`, this.param));
            }
            if( this.photostream ) {
                this.path = String(await s3AccessUtility.getFile(this.image.url, this.param));
            } else {
                this.path = String(await s3AccessUtility.getThumbFile(this.image.url, this.param));
            }
            this.mimeType = this.image.mime;
        } catch( err ) {
            console.error( err );
            this.path = "";
            this.thumbnailPath = "";
        }
    }

    reload(): void {
        if( this.path ) {
            this.path = '';
            if( this.isLoadError == false ) {
                // s3オブジェクト再取得
                setTimeout(this.setImagePath, this.errorRetrySecond * 1000);
                this.errorCounter -= 1;
            }
        }
    }

    onLoadImage(): void {
        this.loading = false;
        this.adjustSize();
    }

    // PDFアイコンクリック時
    pdfIconClick(): void {
        this.pdfFront = !this.pdfFront;
    }

    movieIconClick(): void {
        this.movieFront = !this.movieFront;
    }

    fileName( url: string ): string {
        return S3AccessUtility.decodeName(url);
    }

    async downloadFile(): Promise<void> {
        const s3AccessUtility = S3AccessUtility.getInstance();
        await s3AccessUtility.downloadFile(this.image.url, this.param, this.imageFileName);
    }

}

