import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex)

import { Topic, Message, Comment, User, Domain, Acl } from "@/model";

import { messages, comments, registerDemoData } from "./demo-data";

import categoryModule from "./category-store"
import topicModule from "./topic-store";
import messageModule from "./message-store";
import commentModule from "./comment-store";
import userModule from "./user-store";
import domainModule, { SetPayload } from "./domain-store";
import reactionModule, { PayloadUpdateReaction, PayloadFetchMessageList, PayloadFetchTopicList, FindByTopic } from "./reaction-store";

import S3AccessUtility from '../components/s3-acceess-utility';
import { DirectDomainType, IconReaction, IconReactionType, ReactionTargetType } from '@/API';

export type { UpdatePayload as UpdateCommentPayload } from "./comment-store";
import { isAndroid, isIos, isMobile, osType } from "../mobile";
import { ReactionController } from '@/model/reaction-controller';
import FeatureDao from './feature-dao';

import { LoadingManager } from '@/components/loading/loading-manager'

import SecureLs from "secure-ls";
import DirectUtility from '@/direct-utility';
import AppAuth from '@/aws-config';
import { AppKeys } from '@/direct-app-config';
import GetDomainMembersTask from '@/tasks/get-domain-members-task';
const ls = new SecureLs({ isCompression: false });

// users.users データを復元
function parseUsers( obj: { users: { users: Record<string, Partial<User>[]> }} ) {
    const contents = obj.users.users;
    Object.keys(contents).map( key => {
        const converted = contents[key].map( (content: Partial<User>) => {
            const user = User.create(content);
            const domainRoles = content.domainRoles;
            if( domainRoles !== undefined ) {
                Object.keys(domainRoles).map(key => {
                    user.setDomainRole(key, domainRoles[key]);
                })
            }
            const departments = content.departments;
            if( departments !== undefined ) {
                Object.keys(departments).map( key => {
                    user.setDepartments(key, departments[key]);
                })
            }
            return user;
        })
        contents[key] = converted || [];
    })
    return contents;
}

// users.me object => User
function parseMe( me: Partial<User> ) {
    const user = User.create(me);
    const domainRoles = me.domainRoles;
    if( domainRoles !== undefined ) {
        Object.keys(domainRoles).map(key => {
            user.setDomainRole(key, domainRoles[key]);
        })
    }
    const departments = me.departments
    if( departments !== undefined ) {
        Object.keys(departments).map( key => {
            user.setDepartments(key, departments[key]);
        })
    }
    return user;
}

/** リアクションをstoreに設定するためのペイロード */
export type PayloadReaction = {
    target: Topic | Message | Comment,  //!< 設定する対象
    type: IconReactionType,             //!< リアクション種別
    value: boolean,                     //!< 設定するリアクション値
}

import GetDepartmentsTask from '@/tasks/get-departments-task';
import { AttachmentFileTypesDefault, DownloadDeviceTypes, DownloadDeviceTypesDefault } from '@/suppport-attachment-types';
import { ServerApiAccess } from '@/server-api-access';
import { Domains } from '@/direct-access';

export interface ISearchParam {
    word: string;
}

export default new Vuex.Store({
    plugins: [
        createPersistedState({
            // 検索中の文字列と、デバイス種別のみ永続化する
            // 将来的には各モジュール保存できるようにしとくべき
            // ＊↑persistenceで文字列化するためインスタンスに戻す処理が必要になる
            paths: [
                'lastAccessPath',
                'searchParam',
                'fromQuery',
                'device',
                'invokerapp',
                'user_id',
                'randomNumber1',
                'randomNumber2',
                'randomNumber3',
                'users.me',
                'users.loginUser'
            ],
            storage: {
                getItem: (key) => {
                    try {
                        const data = JSON.parse(ls.get(key));
                        if( data.users.users ) {
                            data.users.users = parseUsers( data );
                        }
                        return data;
                    } catch(err) {
                        return;
                    }
                },
                setItem: (key, value) => ls.set(key, value),
                removeItem: (key) => ls.remove(key),
              },
        }),
    ],
    modules: {
        categories: categoryModule,
        topics: topicModule,
        messages: messageModule,
        comments: commentModule,
        users: userModule,
        reactions: reactionModule,
        domains: domainModule,
    },

    state: {
        session: false, // 現在セッションがあるかどうか
        domainId: "",   // 現在選択中の組織ID
        topicId: "",    // 現在選択中の話題ID
        messageId: "",  // 現在選択中の投稿ID

        navigationGuard: false,

        // 永続化
        lastAccessPath: "",         // 最終アクセスパス(認証のcallback時に利用)
        searchParam: { word: "" },  // 検索パラメータ
        // 永続化(アプリ側からのURLクエリ)
        fromQuery: "",      // アプリ側からのURLクエリを保存する
        lang: "",
        device: "",
        osversion: "",
        invokerapp: "",
        invokerversion: "",
        user_id: "",

        allowFeature: {}, // α／β機能表

        // acl関係
        aclMap: new Map(), // 閲覧管理ダイアログの編集結果を保持 key: 対象のid, value: acl

        // indexedDBの復元に用いるsalt群
        // ※ 数字は indexeddb/webcrypt のsaltの数字と敢えて同期させていない
        randomNumber1: Array.from<number>([]),  // indexeddb/webcrypt salt2に対応
        randomNumber2: Array.from<number>([]), // indexeddb/webcrypt salt3に対応
        randomNumber3: Array.from<number>([]),  // indexeddb/webcrypt salt1に対応
    },
    getters: {
        hasSession: ( state ) => state.session,
        domainId: ( state ) => state.domainId,
        topicId: ( state ) => state.topicId,
        messageId: ( state ) => state.messageId,
        lastAccessPath: ( state ) => state.lastAccessPath,
        navigationGuard: ( state ) => state.navigationGuard,
        searchParam: ( state ) => state.searchParam,
        allowFeature: ( state ) => state.allowFeature,
        salt1: ( state ): Uint8Array => Uint8Array.from(state.randomNumber3),
        salt2: ( state ): Uint8Array => Uint8Array.from(state.randomNumber1),
        salt3: ( state ): Uint32Array => Uint32Array.from(state.randomNumber2),

        // 永続化(アプリ側からのURLクエリ)
        fromQuery: ( state ) => state.fromQuery,
        device: ( state ) => state.device,
        userId: ( state ) => state.user_id,
        invokerapp: ( state ) => state.invokerapp,

        // Utility
        isMobile: ( state ) => isMobile( state.device ),
        isAndroid: ( state ) => isAndroid( state.device ),
        isIos: ( state ) => isIos( state.device ),
        os: ( state ) => osType( state.device ),
        isPro: ( state, getter ) => getter[ "domains/isPro" ],

        // me
        me: ( state, getter ) => getter[ "users/me" ] as User|undefined,
        getUser: ( state, getter ) => ( cognitoUserId: string, domainId?: string ) => {
            const arg = domainId ? domainId : state.domainId;
            return getter[ "users/get" ]( cognitoUserId, arg );
        },

        // topic
        getTopics: ( state, getter ) => ( domainId: string ): Topic[] => getter[ "topics/get" ]( domainId ),
        getTopic: ( state, getter ) => ( domainId: string, topicId: string ): Topic|undefined => getter["topics/getOne"]( domainId, topicId ),

        // message
        getMessages: ( state, getter ) => ( domainId: string, topicId: string ): Message[] => getter[ "messages/get" ]( domainId, topicId ),
        getMessage: ( state, getter ) => ( domainId: string, topicId: string, messageId: string ): Message|undefined => {
            const messages = getter[ "messages/get" ]( domainId, topicId ) as Message[];
            return ( messages || [] ).find( m => m.id == messageId );
        },

        // comment
        getComments: ( state, getter ) => ( domainId: string, topicId: string, messageId: string, ) => getter[ "comments/get" ]( topicId, messageId ),
        getComment: ( state, getter ) => ( domainId: string, topicId: string, messageId: string, commentId: string ) => {
            const comments = getter[ "comments/get" ]( topicId, messageId ) as Comment[];
            return ( comments || [] ).find( c => c.id == commentId )
        },

        getAclData: ( state, getters ) => (id: string) => {
            return state.aclMap.get(id);
        },
    },
    mutations: {
        setSession( state, payload:{ hasSession: boolean } ) {
            state.session = payload.hasSession;
        },

        // direct組織IDを設定する
        setDirectDomainId( state, directDomainId: string ) {
            state.domainId = directDomainId;
        },

        setTopicId( state, topicId: string ) {
            state.topicId = topicId;
        },

        setSearchParam(state, param: ISearchParam ) {
            state.searchParam = param;
        },


        setNavigationGuard( state, navigationGuard: boolean ) {
            state.navigationGuard = navigationGuard;
        },

        setFromQuery( state, query: string ) { state.fromQuery = query ?? ""; },
        setDevice( state, device: string = "" ) { state.device = device ?? ""; },
        setUserId( state, userId: string ) { if( typeof(userId) == "string" && 0 < userId.length ) state.user_id = userId; },
        setInvokerApp( state, invoker: string = "" ) { state.invokerapp = invoker ?? ""; },

        setLastAccessPath( state, path: string ) {
            state.lastAccessPath = path || "";
        },

        setAllowFeature( state, allowFeature: { [keyword: string]: string } ) {
            state.allowFeature = allowFeature;
        },

        setAclData( state, aclData: { id: string, acl: Acl|undefined }  ) {
            // acl が undefined の場合 reset
            if( aclData.acl === undefined ) {
                state.aclMap.delete(aclData.id);
            } else {
                state.aclMap.set(aclData.id, aclData.acl);
            }
        },

        setSalt1( state, salt: Uint8Array ) {
            state.randomNumber3 = Array.from<number>(salt);
        },

        setSalt2( state, salt: Uint8Array ) {
            state.randomNumber1 = Array.from<number>(salt);
        },

        setSalt3( state, salt: Uint32Array ) {
            state.randomNumber2 = Array.from<number>(salt);
        },
    },
    actions: {
        async setSession( { commit }, payload: { hasSession: boolean } ): Promise<void> {
            commit("setSession", payload );
        },

        /** Reactionを設定する */
        async setReaction( context: unknown, payload: PayloadReaction ) {
            const me = this.getters["users/me"] as User;

            // 自分のReaction設定を保存
            this.dispatch( "users/setMeReaction", payload );

            // ReactionStore にReaction設定を保存
            const reactionContext: PayloadUpdateReaction = {
                ...payload,
                userDirectId: me.directId,
            }
            this.dispatch( "reactions/update", reactionContext );
        },

        // サーバ側から話題一覧を取得する
        async fetchTopics( { state } ) {
            const domainId = state.domainId;
            return await this.dispatch( "toipcs/fetch", { domainId } );
        },

        /** localStorageからデータを復元したもので元がクラスインスタンスのものを再生成する */
        async recreateData({state}, domainId) {
            // (新規ページなどで)遷移前パスが存在しない時、前回のアクセスパスを利用する
            const splits = state.lastAccessPath.split('/');
            const id = splits.length ? splits[1] : '';
            const lastAccessPath = domainId || id;
            // localStorageのusers/meをUser型にセットし直す
            const user = parseMe(this.getters["users/me"]);
            await this.dispatch("users/setMe", user);
            await this.dispatch("users/restore");
            await this.dispatch("topics/restore");
            await this.dispatch("domains/restore");
        },

        // DBからデータロードする
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        async fetchData(  { state }, { domainId, topicId, prevDomainId }: { domainId: string, topicId?: string, prevDomainId?: string } ) {
            try {
                if( LoadingManager.isLoading('on-fetch') ) {
                    return;
                }

                const isChangedDomain = domainId !== prevDomainId; // 組織切替が発生したか
                LoadingManager.start('on-fetch', '読み込み中...')
                // ※組織配下のカテゴリ及び話題データが投稿データ一覧でも必要
                switch( domainId ) {
                    case undefined:
                    case "":
                        // 組織名指定無し
                        LoadingManager.stop('on-fetch');
                        return;
                    case "login":
                    case "login-continue":
                    case "login-continue-stg":
                    case "login-continue-dev":
                    case "login-mobile-redirect":
                    case "logout":
                        // ログイン関連処理時
                        LoadingManager.stop('on-fetch');
                        return;
                }
                console.info("{fetchData domainId:%s topicId:%s}", domainId, topicId)

                // 最終アクセスパスを覚えておく
                const lastPath = topicId ? `/${domainId}/${topicId}` : `/${domainId}`;
                this.dispatch("setLastAccessPath", lastPath );

                // 組織状態のチェック
                const isDomainEnable = this.getters[ "domains/isEnable" ](domainId) as boolean;
                if( !isDomainEnable ) {
                    LoadingManager.stop('on-fetch');
                    return;   // 掲示板が有効ではないためロードしない
                }

                // 自分の状態を取得する
                const me = this.getters["users/getLoginUser"];
                if( me == undefined || typeof me == "number" ) {
                    // 再ログインに流す。NavBar側の処理でそちらに流れる
                    LoadingManager.stop('on-fetch');
                    return;
                }

                const setDomainId = this.getters['domains/getDomainId'];
                const departments = this.getters['domains/getDepartments'] || [];
                if( isChangedDomain || setDomainId !== domainId || !departments.length || !LoadingManager.isEndInitialLoad() ) {
                    // directの組織参加者と部署構造を取得する
                    // dynamoDBの取得処理と並行して走らせるためにawaitは付与しない
                    this.dispatch('fetchDomainInfo', domainId);

                    // 組織の固有設定をDBから取得する
                    this.dispatch( 'domains/fetchDomainSettings', { domainId: domainId } );

                    // directの組織に関連づいたアプリ一覧を取得する
                    this.dispatch('domains/fetchSolutions', { domainId: domainId } );
                }

                // カテゴリーデータ全取得
                const categories = this.getters["categories/get"]( domainId );
                if( !categories || !LoadingManager.isEndInitialLoad() ) {
                    await this.dispatch( 'categories/fetch', { data: domainId } );
                }

                /* 並列処理の対象 */
                const topicProcess = async () => {
                    // 話題データ全取得
                    const topics = this.getters["topics/get"]( domainId );
                    if( !topics || topics.length == 1 && topics.find((t:Topic) => t.id == topicId) || !LoadingManager.isEndInitialLoad() ) {
                        await this.dispatch( 'topics/fetch', { domainId: domainId } );
                    } else {
                        this.dispatch( 'topics/fetch', { domainId: domainId } );
                    }
                }

                const topicReactionProcess = async () => {
                    // 話題リアクションデータ全取得
                    const type = IconReactionType.FAVORITE
                    const payload: PayloadFetchTopicList = { domainId, type };
                    await this.dispatch( 'reactions/fetchDomainTopics', payload );
                }

                const messageProcess = async () => {
                    // 話題に紐付いた投稿データを全ロード
                    const messages = this.getters["messages/get"]( domainId, topicId );
                    if( !messages || !LoadingManager.isEndInitialLoad() ) {
                        const messageId = state.messageId ?? undefined;
                        await this.dispatch( "messages/fetch", { domainId: domainId, topicId: topicId, messageId: messageId } );
                    } else {
                        const messageId = state.messageId ?? undefined;
                        this.dispatch( "messages/fetch", { domainId: domainId, topicId: topicId, messageId: messageId } );
                    }
                }

                const commentProcess  = async () => {
                    // 話題に紐付いたコメントデータを全取得
                    const comments = this.getters["comments/getByTopic"]( topicId );
                    if( !comments || !LoadingManager.isEndInitialLoad() ){
                        await this.dispatch( "comments/fetch", { topicId } );
                    } else {
                        this.dispatch( "comments/fetch", { topicId } );
                    }
                }

                const messageAndCommentReactionsProcess = async () => {
                    if( !topicId ) return;
                    // 投稿/コメントリアクションデータ全取得
                    await this.dispatch( 'reactions/fetchMessageAndCommentReactions', {domainId, topicId} );
                }

                const usersProcess = async () => {
                    // ユーザー一覧の取得
                    const users = this.getters["users/getByDomainId"]( domainId );
                    if( isChangedDomain && users.length <= 0 || !LoadingManager.isEndInitialLoad() ) {
                        await this.dispatch( 'users/fetch', { data: domainId } );
                    } else if( isChangedDomain && users.length > 0) {
                        this.dispatch( 'users/fetch', { data: domainId } );
                    }
                }

                // 並列処理部
                if( topicId === undefined || topicId.length == 0 ) {   // 話題一覧のデータロード
                    // ユーザ, 話題, 話題に関するリアクション
                    await Promise.all([ usersProcess(), topicProcess(), topicReactionProcess() ]);
                } else {                       // 投稿一覧のデータロード
                    // ユーザ, 話題, 話題に関するリアクション, 投稿, 投稿に関するリアクション, コメント, コメントに関するリアクション
                    await Promise.all([ usersProcess(),  topicProcess(), topicReactionProcess(), messageProcess(), commentProcess(), messageAndCommentReactionsProcess() ]);
                }
                LoadingManager.initalLoad();
                LoadingManager.stop('on-fetch');
            } catch ( err ) {
                console.log("fetchData Error", err);
                LoadingManager.stop('on-fetch');
            }
        },

        /** directのログイン情報のロード */
        async fetchDirectInfo( { commit }, { domainId }: { domainId: string } ): Promise<void> {
            LoadingManager.start( "loadDirectInfo", "directから情報取得中..." );
            const user = await DirectUtility.getDirectUser();
            await this.dispatch("users/setLoginUser", user);
            if( typeof user == "number" ) {
                switch( user ) {
                    case 401: AppAuth.signOut(); break;
                    default:  console.error( "status:", user ); break;
                }
                LoadingManager.stop( "loadDirectInfo" );
            } else if( user ) {
                // 投稿一覧でリロード時も組織設定をセットする
                await this.dispatch("fetchDirectDomain", domainId );
                const topicId = this.getters["topicId"];
                // directユーザ情報と組織設定を読み込んだ後に再度データ読み込み
                await this.dispatch("fetchData", { domainId, topicId });
            } else {
                LoadingManager.stop( "loadDirectInfo" );
            }
        },

        /** direct組織設定をロード */
        async fetchDirectDomain( { commit }, domainId: string ): Promise<void> {
            LoadingManager.start( "loadDirectInfo", "directから情報取得中..." );
            const loginUser = this.getters["users/getLoginUser"];
            if( loginUser == undefined || typeof loginUser == "number" ) {
                LoadingManager.stop( "loadDirectInfo" );
                return;
            }
            const domains: Domains = loginUser.domains;
            await this.dispatch("users/setMeDomainSetting", domains);
            const domain = domains.find(domain => domain.domain_id_str == domainId );
            if( !domain ) {
                LoadingManager.stop( "loadDirectInfo" );
                return;
            }
            const allow_attachment_type = domain.setting.allow_attachment_type || 0;
            const allow_attachment_type_by_client = domain.setting.allow_attachment_type_by_client;
            const allowed_save_attachments_device_type = ( domain.setting.allow_save_attachments || DownloadDeviceTypesDefault ) as DownloadDeviceTypes;

            // アップロード先 設定場所 (domainSetting の upload_pathにセット)
            // const upload_path = "uploads";
            const domainSetting: SetPayload = {
                allow_attachment_type: allow_attachment_type,
                allow_attachment_type_by_client: allow_attachment_type_by_client,
                allow_save_attachments: allowed_save_attachments_device_type,
                domainId: domain.domain_id_str,
                domainName: domain.domain_name,
                domainRole: domain.role || undefined,
                solution_ids: domain.contract.solution_ids || [],
                direct_app_keys: (domain.contract.direct_app_keys || []) as AppKeys[],
            };
            await this.dispatch("domains/setDomainSetting", domainSetting);
            LoadingManager.stop( "loadDirectInfo" );
        },

        /** direct組織情報(部署, 組織参加者)取得タスク */
        async fetchDomainInfo( { dispatch, rootGetters }, domainId: string ): Promise<void> {
            const getDepartmentsTask = async () => {
                await GetDepartmentsTask.run( domainId, this );
            }
            const getDomainMembersTask = async () => {
                await GetDomainMembersTask.run( domainId, this );
            }
            await Promise.all( [getDepartmentsTask(), getDomainMembersTask()] );
        },

        // 同期処理
        async syncLoad({ state }) {
            const domainId =  state.domainId;
            LoadingManager.resetInitalLoad();
            LoadingManager.start('on-reload', '同期中...', 'rgba(255,255,255,.7)')
            const acceess = new ServerApiAccess();
            const result = await acceess.upsertDepartments(domainId);
            await this.dispatch("fetchDirectInfo", { domainId: domainId });
            LoadingManager.stop('on-reload');
        },

        /** direct組織IDをsetする */
        async setDomainId( { commit }, domainId: string ): Promise<void> {
            commit( 'setDirectDomainId', domainId );
        },

        /** topicIdをsetする */
        async setTopicId( { commit }, topicId: string ): Promise<void> {
            commit( 'setTopicId', topicId );
        },

        /** デモデータをDBから削除する */
        async removeDemoData(): Promise<void> {
            await this.dispatch( "fetchData" );
            await this.dispatch( 'topics/delete', { data: "1" } ); // 組織ID:1 のデータ削除
        },

        /** デモデータの登録作業 */
        async registerDemoData( context: unknown, type: "category"|"topic"|"message"|"comment"): Promise<void> {
            await registerDemoData( this, type );
        },

        /** S3へデモ投稿/コメントデータの添付ファイルを配置 */
        async setS3Files(): Promise<void> {
            await S3AccessUtility.setDemoFiles(messages, comments);
        },

        /** S3のデモファイルを削除 */
        async resetS3Files(): Promise<void> {
            await S3AccessUtility.resetDemoFiles();
        },

        /** 検索パラメータを保存 */
        async setSearchParam( { commit }, param: ISearchParam ): Promise<void> {
            commit( 'setSearchParam', param );
        },

        /** 検索パラメータの取得 */
        async getSearchParam( { state } ): Promise<ISearchParam> {
            return state.searchParam;
        },

        async setNavigationGuard( {commit}, navigationGuard: boolean ): Promise<void> {
            commit( 'setNavigationGuard', navigationGuard );
            // ブラウザのページ離脱アラートをセット
            // (return に""離脱"と入れてるが、別に何でもいい。ただし、空文字はダメ)
            window.onbeforeunload = navigationGuard? () => { return "離脱"; } : null;
            console.log("navigationGuard", navigationGuard );
        },

        /** デバイス情報を保存 */
        async setDevice( { commit }, device: string ): Promise<void> { commit( 'setDevice', device ); },

        /** UserId を設定 */
        async setUserId( { commit }, userId: string ): Promise<void> { commit( 'setUserId', userId ); },

        /** InvokerApp情報を保存 */
        async setInvokerApp( { commit }, invokerApp: string ): Promise<void> { commit( 'setInvokerApp', invokerApp ); },

        // 永続化(アプリ側からのURLクエリ)
        async setFromQuery( { commit }, query: { [key:string]: string } ): Promise<void> {
            const str = Object.keys( query )
                        .filter( key => key !== "domain_id" )   // domain_idは残さない
                        .map( key => `${key}=${query[key]}` )   // パラメータ構築
                        .join("&");
            if( str ) commit( "setFromQuery", str );
        },

        /** 最終アクセスパス */
        async setLastAccessPath( { commit }, path: string ): Promise<void> {
            commit( 'setLastAccessPath', path );
        },

        /** α／β機能表をロード */
        async loadAllowFeature( { commit } ): Promise<void> {
            const allowFeature = await FeatureDao.list();
            commit( 'setAllowFeature', allowFeature );
        },

        async setAclData( { commit }, aclData: { id: string, acl: Acl|undefined } ): Promise<void> {
            commit( 'setAclData', aclData);
        },

        /** saltセット */
        async setSalt1( { commit }, salt: Uint8Array ): Promise<void> {
            commit( 'setSalt1', salt);
        },

        async setSalt2( { commit }, salt: Uint8Array ): Promise<void> {
            commit( 'setSalt2', salt);
        },

        async setSalt3( { commit }, salt: Uint32Array ): Promise<void> {
            commit( 'setSalt3', salt);
        },
    },
})


